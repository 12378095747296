// @import "/src/assets/components/input/v1/app/input-styles.scss";

app-member-form,
ngx-reset-password,
app-login-page {
    ion-item {
        margin: 5px 0px;
        --border-style: 0;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        --ripple-color: transparent;
        --background-activated: transparent;
        --background-activated-opacity: transparent;
        --background-focused: transparent;
        --background-focused-opacity: transparent;
        --background-hover: transparent;
        --background-hover-opacity: transparent;

        .form_item {
            width: 100%;
        }

        &.item-label-floating {
            app-label {
                ion-label {
                    transform: translateY(205%) !important;
                    padding-left: 20px;
                    font-size: 16px !important;
                    --color: #5a5a5a !important;
                    text-align: left;
                }
            }
            a24-input {
                display: block;
                width: 100%;
                ion-input {
                    border: 1px solid var(--ion-border-color);
                    border-radius: var(--border-radius-xs);
                    --padding-start: 20px !important;
                    --padding-end: 20px !important;
                    height: 64px;
                    opacity: 1 !important;
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    // left: 20px;
                }
                .uppercase{
                    input{
                        text-transform: uppercase;
                    }
                }
            }
            // &.item-has-focus {
            //     ion-label {
            //         transform: translateY(205%) !important;
            //     }
            // }

            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    background: var(--ion-white-color);
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 10px;
                    display: table;
                }
                .with-icon {
                    &.left {
                        ion-avatar {
                            display: block;
                        }
                    }
                }
            }
            .with-icon {
                width: 100%;
                position: relative;
                ion-avatar {
                    position: absolute;
                    width: 46px;
                    height: 46px;
                    font-size: 28px;
                    text-align: center;
                    top: 15px;
                    bottom: 0;
                    ion-icon {
                        color: var(--ion-input-icon-color);
                    }
                }
                &.left {
                    ion-avatar {
                        left: 10px;
                        display: none;
                    }
                    ion-input {
                        --padding-start: 50px !important;
                        --padding-end: 20px !important;
                    }
                }
                &.right {
                    ion-avatar {
                        right: 10px;
                    }
                    ion-input {
                        --padding-start: 20px !important;
                        --padding-end: 50px !important;
                    }
                }
            }

            a24-ion-datepicker {
                border: 1px solid var(--ion-border-color);
                border-radius: var(--border-radius-xs);
                --padding-start: 20px !important;
                --padding-end: 20px !important;
                width: 100%;
                max-width: 100%;
                height: 64px;
                opacity: 1 !important;
                .form_group {
                    position: relative;
                    .show_time {
                        ion-input {
                            height: 64px;
                            --padding-top: 0;
                            --padding-bottom: 0;
                            line-height: 64px;
                            input {
                                padding: 8px 20px;
                            }
                        }
                        ion-icon {
                            position: absolute;
                            right: 20px;
                            top: 24px;
                        }
                    }
                }
                &::part(icon) {
                    top: 10px;
                }
                ion-item {
                    &.item-has-focus,
                    &.item-has-value {
                        app-label {
                            ion-label {
                                transform: translateY(50%) !important;
                            }
                        }
                    }
                    .form_group {
                        width: 100%;

                        .show_time {
                            ion-input {
                                height: 64px;
                                --padding-top: 0;
                                --padding-bottom: 0;
                                line-height: 64px;
                            }
                            ion-icon {
                                position: absolute;
                                right: 10px;
                                top: 24px;
                            }
                        }

                        ion-input {
                            height: 64px;
                            --padding-top: 0;
                            --padding-bottom: 0;
                            line-height: 64px;
                        }
                        ion-icon {
                            position: absolute;
                            right: 10px;
                            top: 24px;
                        }
                    }
                }
            }
        }

        /* Animation */
        ion-input {
            &::after {
                content: "";
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                width: 0;
                height: 62px;
                border-radius: var(--border-radius-xs);
                border: 1px solid var(--border-focus-color);
                z-index: 9;
                visibility: hidden;
            }
        }
        ion-input.has-focus {
            &::after {
                width: 100%;
                transition: 0.1s;
                visibility: visible;
            }
        }

        /* Select */
        &.item-select.item-label-floating {
            app-label {
                ion-label {
                    transform: translateY(190%) !important;
                    padding-left: 20px;
                }
            }
            app-select-component {
                display: block;
                width: 100%;
                ion-select {
                    border: 1px solid var(--ion-border-color);
                    border-radius: var(--border-radius-xs);
                    --padding-start: 20px !important;
                    --padding-end: 15px !important;
                    width: 100%;
                    max-width: 100%;
                    height: 64px;
                    opacity: 1 !important;
                    &::part(icon) {
                        top: 0;
                        transform: unset;
                        width: 20px;
                        height: 20px;
                        opacity: 0.5;
                    }
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    // left: 20px;
                }
            }

            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    background: var(--ion-white-color);
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 10px;
                }
            }
        }

        /* Multi Select */

        &.item-ionic-selectable.item-label-floating {
            app-label {
                ion-label {
                    transform: translateY(190%) !important;
                    padding-left: 20px;
                }
            }
            app-select-component {
                display: block;
                width: 100%;
                position: relative;
                ionic-selectable {
                    border: 1px solid var(--ion-border-color);
                    border-radius: var(--border-radius-xs);
                    --padding-start: 10px !important;
                    --padding-end: 20px !important;
                    width: 100%;
                    max-width: 100%;
                    height: 64px;
                    opacity: 1 !important;
                    .ionic-selectable-icon {
                        width: 20px;
                        position: absolute;
                        top: 10px;
                        right: 15px;
                    }
                    .ionic-selectable-value-item {
                        display: inline-block;
                        margin: 6px 10px 6px 0px;
                        // background: var(--ion-multi-select-bg-color);
                        padding: 5px 8px;
                        border-radius: 8px;
                    }
                }
                .valid_msg {
                    position: relative;
                    bottom: 0;
                    // left: 20px;
                }
            }

            &.item-has-focus,
            &.item-has-value {
                ion-label {
                    transform: translateY(50%) !important;
                    background: var(--ion-white-color);
                    z-index: 99;
                    position: relative;
                    padding: 0px 10px;
                    left: 10px;
                }
            }
        }

        // &.item-label-stacked {
        //     app-label {
        //         display: block;
        //         width: 100%;
        //         margin-bottom: 15px;
        //         font-size: 18px;
        //     }
        //     a24-input {
        //         display: block;
        //         width: 100%;
        //         ion-input {
        //             border: 1px solid var(--ion-border-color);
        //             border-radius: var(--border-radius-xs);
        //             --padding-start: 15px !important;
        //             --padding-end: 15px !important;
        //             height: 46px;
        //         }
        //         .with-icon {
        //             width: 100%;
        //             position: relative;
        //             ion-avatar {
        //                 position: absolute;
        //                 width: 46px;
        //                 height: 46px;
        //                 font-size: 28px;
        //                 text-align: center;
        //                 top: 8px;
        //                 bottom: 0;
        //                 ion-icon {
        //                     color: var(--ion-input-icon-color);
        //                 }
        //             }
        //             &.left {
        //                 ion-avatar{
        //                     left: 0;
        //                 }
        //                 ion-input {
        //                     --padding-start: 50px !important;
        //                     --padding-end: 15px !important;
        //                 }
        //             }
        //             &.right {
        //                 ion-avatar{
        //                     right: 0;
        //                 }
        //                 ion-input {
        //                     --padding-start: 15px !important;
        //                     --padding-end: 50px !important;
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    ion-avatar {
        display: none;
    }
}
