/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Font Import */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

//font-family: 'Inter', sans-serif;
@import "/src/assets/form-v1-style.scss";

// Common Styles
body {
  font-family: "Raleway";
  background: #ffffff;
  -webkit-touch-callout: default !important;
                  -webkit-tap-highlight-color: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ion-title,
ion-label {
  font-family: "Raleway", sans-serif;
}
p {
  color: #1d1d1d;
}
// Reset Styles

::selection {
  background-color: #195a03;
  color: #fff;
}

// Theme color styles
$theme_default_color: #195a03;
$default_text_color: #1d1d1d;
$default_light_bg: #fafafa;
$text_white_color: #fff;
$white_bg: #fff;
$inner_bg_color: #ffffff;

:root {
  --ion-background-color: #f7f7f7;
  --ion-text-color: #1b1b1b;
  --ion-backdrop-color: #1b1b1b;
  --ion-backdrop-opacity: 0.5;
  --ion-border-color: #195a03;
  --ion-item-border-color: #d6d6d6;
  --ion-toolbar-background: #ffffff;
  --ion-toolbar-color: #195a03;
  --ion-toolbar-segment-color: #195a03;
  --ion-item-background: transparent;
  --ion-item-color: #1b1b1b;
  --ion-placeholder-color: #1b1b1b;
  --ion-hover-color: #f7cfc7;
  --ion-sidemenu-color: #fff;
  --ion-white-color: #fff;
  --ion-theme-color: #195a03;
  --ion-input-icon-color: #c9c6c6;
  --ion-input-bg-color: #f5f5f5;
  --border-focus-color: #e73814;
  --ion-active-label-color: #9b9494;
  --ion-active-label-bg-color: #fdeeee;
  --ion-multi-select-bg-color: #f2f2f2;
  --ion-item-background-black: #707070;
  --ion-item-bb-text-label: #c4c4c4;
  --ion-icon-color: #9f9f9f;
  --ion-card-background-color: #4e4e4e;
  --ion-color-base: var(--ion-color-secondary, #195a03) !important;

  /* border-radius */
  --border-radius-xl: 20px;
  --border-radius-l: 16px;
  --border-radius-md: 12px;
  --border-radius-s: 8px;
  --border-radius-xs: 5px;

  --ion-font-family: "Raleway", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: unset;
  -webkit-text-fill-color: unset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Common Styles CSS */

.no-border {
  --border-style: 0;
}
.no-pading {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}
.content_box {
  background: var(--ion-white-color);
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 30px;
}
.no-flex {
  display: unset !important;
}
.inner_title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  span {
    color: var(--ion-theme-color);
  }
}
.border-radius-xl {
  border-radius: var(--border-radius-xl);
}
.border-radius-l {
  border-radius: var(--border-radius-l);
}
.border-radius-md {
  border-radius: var(--border-radius-md);
}
.border-radius-s {
  border-radius: var(--border-radius-s);
}
.border-radius-xs {
  border-radius: var(--border-radius-xs);
}
.hide-err {
  font-size: 14px;
  color: var(--ion-color-danger);
}
// ion-content ion-router-outlet {
//   margin-top: 80px;
// }
.code_list .country-code {
  margin-right: 16px;
  width: 50px;
}
ion-label,
ion-input {
  --color-activated: unset !important;
  --color-focused: unset !important;
  --color-hover: unset !important ;
  --placeholder-color: unset;
  --placeholder-font-style: unset;
  --placeholder-font-weight: unset;
  --placeholder-opacity: 0.5;
  transition: unset !important;
  transform: unset !important;
}

/* Firefox */
html {
  scrollbar-color: var(--scrollbar-color) !important;
  scrollbar-width: var(--scrollbar-width) !important;
}

// Theme styles
.wrapper {
  width: 100%;
  max-width: 1530px;
  margin: 0 auto;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
  &.align-items-center {
    align-items: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}
.pb-80 {
  padding-bottom: 80px;
}
.p-80 {
  padding: 80px 0px;
}
.p-100 {
  padding: 100px 0px;
}
.mt-50 {
  margin-top: 50px;
}
.bg_grey {
  background: $default_light_bg;
}
.text-align-center {
  text-align: center;
}
.sec_title {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
  color: $default_text_color;
}
.ii-no-border {
  --border-style: unset;
}
.ii-no-pad {
  --padding-start: 0;
  --padding-end: 0;
}
.ii-no-ps {
  --padding-start: 0;
}
.ii-no-pe {
  --padding-end: 0;
}
.transparent_btn {
  --background: transparent;
  --box-shadow: none;
  color: $theme_default_color;
  &.ion-activated {
    --background-activated: transparent !important;
    --background-focused: transparent !important;
    --background-hover: transparent !important;
  }
}
ion-button {
  text-transform: capitalize !important;
  font-size: 20px;
  &.ion-activated {
    --background-activated: transparent !important;
    --background-focused: transparent !important;
    --background-hover: transparent !important;
  }
}
.no-overflow {
  --overflow: hidden !important;
}

.no_data_msg {
  text-align: center;
  margin-top: 30%;
  img {
    display: none;
  }
}

// table rows selection footer

.rows-selection {
  &::part(content) {
    width: 140px;
    --offset-x: unset;
    --offset-y: -20px;
  }
}


// upload file

.upload_files_v2 {
  margin-top: 25px !important;
  app-label {
    position: absolute;
    left: 0;
    z-index: 99999;
    transform: translateY(-110%) !important;
    width: 60% !important;
    text-align: center;
    ion-label {
      text-overflow: unset !important;
      overflow: visible !important ;
      transform: translateY(50%) !important;
      background: var(--ion-white-color);
      z-index: 99999;
      position: relative;
      padding: 0;
      display: table;
    }
  }
  &::part(native) {
    width: 100%;
    display: flex;
    align-items: center;
    height: 62px;
    border: 1px solid var(--ion-border-color);
    border-radius: var(--border-radius-xs);
  }
  .image_update_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    width: 100%;
    .upload_img {
      width: 100%;
      height: 60px;
      top: 0;
      z-index: 99999;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        height: 60px;
        width: auto;
      }
      ion-icon {
        display: none;
      }
    }
    app-file-upload {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f7fdf4;
      cursor: pointer;
      position: relative;
      z-index: 9999;
      ion-input {
        cursor: pointer;
        input{
          cursor: pointer;
        }
        &.hui-upload {
          height: 60px;
         
        }
      }
      ion-button {
        padding: 0;
        margin: 0;
        height: 60px;
        cursor: pointer;
        &::part(native) {
          background-color: #f7fdf4;
          box-shadow: unset;
          color: #195a03;
          font-size: 15px;
          font-weight: 500;
        }
      }
      .image_upload_cancel_btn {
        position: absolute;
        left: -145px;
        top: 22px;
        ion-icon {
          font-size: 20px;
          color: #e50112;
          cursor: pointer;
        }
      }
    }

    .form_image_upload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .image_name_text {
        margin-left: 20px;
      }
      .upload_image_text {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        img {
          height: 60px;
        }
      }
    }
  }
  &.item-has-focus {
    app-label {
      transform: unset !important;
      position: absolute;
      left: 0;
      top: 11px;
      ion-label {
        transform: unset !important;
        background: var(--ion-white-color);
        z-index: 99999;
      }
    }
  }
  &.item-has-value, &.file_uploaded {
    app-label {
      transform: translateY(-105%) !important;
      top: 5px;
    }
  }
}

.tooltip_input {
  position: relative;
  .tooltip_text {
    position: absolute;
    top: 48px;
    right: 30px;
    z-index: 99999;
    ion-icon {
      width: 20px;
      height: 20px;
      color: #12880e;
    }
  }
  .tooltip_small_text {
    position: absolute;
    top: 48px;
    right: 30px;
    z-index: 99999;
    .tooltip-bottom {
      &:after {
        top: -60px;
        z-index: 99999;
      }
      ion-icon {
        width: 20px;
        height: 20px;
        color: #12880e;
      }
    }
  }
}

.extra_column{
  ng2-smart-table{
    .custom-smart-table{
      .table-responsive{
        table{
          display: block;
        }
      }
    }
  }
}

.page_content {
  .form_page_container {
    .page_container {
      .form_container {
        .filed_container {
          .input_container {
            .checkbox_styles {
              .content_text {
                .text {
                  app-check-box-component {
                    ion-list {
                      ion-item {
                        --padding-start: 0;
                        --min-height: 30px;
                        margin: 0 !important;
                        ion-checkbox {
                          margin-right: 15px;
                          margin-top: 0;
                          margin-bottom: 0;
                        }
                      }
                    }
                    .hide-err {
                      padding-top: 0;
                      padding-bottom: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// variables tips
$tip-distance: 24px;
$tip-triangle-width: 6px;
$tip-color-fallback: #195a03;
$tip-color: #195a03;
// style the tooltip anchor
.tooltip {
  position: relative;
  display: block;
  cursor: pointer;
  white-space: pre-wrap;
  z-index: 99999;
  &:after {
    content: attr(data-tip);
    position: absolute;
    bottom: 0;
    right: 25px;
    min-width: 200px;
    max-width: 300px;
    padding: 5px 10px;
    pointer-events: none;
    font-size: 12px;
    background-color: #195a03;
    color: #fff;
    border-radius: 4px;
  }
  // this creates the little triangle
  // &:before {
  //   content: "";
  //   width: 0;
  //   height: 0;
  //   position: absolute;
  //   left: 5px;
  //   bottom: $tip-distance - $tip-triangle-width;
  //   border-left: $tip-triangle-width solid transparent;
  //   border-right: $tip-triangle-width solid transparent;
  //   border-top: $tip-triangle-width solid $tip-color-fallback; // IE8 fallback
  //   border-top: $tip-triangle-width solid $tip-color;
  // }
  &:before,
  &:after {
    display: block;
    visibility: hidden; // because opacity:0 is not enough for IE8
    opacity: 0;
    transition: opacity 0.2s ease-out, margin 0.2s ease-out;
  }
  &:hover {
    color: #999;
    &:before,
    &:after {
      opacity: 1;
      margin-bottom: 3px;
      visibility: visible;
    }
  }
}

.tooltip-bottom {
  &:after {
    top: -130px;
    z-index: 99999;
  }
  &:before {
    top: -26px;
    border-top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    z-index: 99999;
    transform: rotate(179deg);
  }
  &:hover {
    &:before,
    &:after {
      bottom: auto;
      margin-bottom: 0;
      margin-top: 3px;
    }
  }
}

.ion-color-danger {
  --ion-color-base: #195a03 !important;
}

ion-split-pane {
  --side-max-width: 15% !important;
}

.close_bg,
.open_popover .menu-side-start:after,
.open_popover ion-split-pane {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  background: #00000059;
  z-index: 9999;
}

.link {
  &:hover {
    color: #77be2d !important;
    cursor: pointer;
  }
}

.agree-label {
  padding: 0;
  p {
    color: #eb0000 !important;
    font-size: 14px;
    background-color: #f6e6e6;
    padding: 8px 18px !important;
    margin-top: 20px;
    border: 1px solid #eb0000;
    border-radius: 4px;
  }
  .show_error_code {
    span {
      color: #c5c5c5 !important;
      font-size: 12px;
    }
    &:last-child() {
      font-weight: 500 !important;
      font-size: 12px;
    }
  }
}

.showCaptcha_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .agent-captcha {
    .d-flex {
      align-items: center;
      justify-content: space-between;
      span {
        .capcha-btn {
          svg {
            width: 20px;
            fill: white;
          }
        }
      }
    }
  }
  a24-input {
    ion-input {
      input {
        height: 64px;
        border: 1px solid #195a03 !important;
        border-radius: 5px !important;
        font-size: 25px !important;
        text-align: center !important;
        line-height: 0;
        padding: 0 !important;
        margin: 0;
        width: 250px !important;
        font-weight: 500;
      }
    }
  }
}

.header_sec_left {
  .user_profile {
    ion-toolbar {
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
    }
  }
}

// search

.search_bar {
  // margin-right: 16px;
  // margin-bottom: 20px;
  box-shadow: -1px 2px 5px 0 #0c0c0c14;
  a24-search {
    .search_input {
      position: relative;
      padding: 0;
      ion-searchbar {
        padding: 0;
        height: 44px;
        --border-radius: 5px;
        --border-top-right-radius: 0 !important;
        --border-bottom-right-radius: 0 !important;
        --box-shadow: -1px 2px 5px 0 #0c0c0c14;
        contain: unset;
        .searchbar-input-container {
          height: 44px !important;
          .searchbar-search-icon {
            position: absolute;
            right: 14px !important;
            left: auto;
            z-index: 9998;
            color: #fff;
          }
          .searchbar-clear-button {
            right: 54px !important;
          }
          .searchbar-input {
            -webkit-padding-start: 16px;
            padding-inline-start: 16px;
            padding-inline-end: 74px;
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 14px;
            width: 100%;
            max-width: 270px;
            background-color: #ffffff;
            --box-shadow: -1px 2px 5px 0 #0c0c0c14;
          }
        }
      }

      .search_icon {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .button-holder {
        position: absolute;
        top: 0;
        right: 0;
        background: $theme_default_color;
        width: 48px;
        height: 44px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        --box-shadow: -1px 2px 5px 0 #0c0c0c14;
        ion-icon {
          display: none;
        }
      }
    }
  }
}

//backdrop

.huihui-backdrop {
  position: relative;
}
.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
  height: 100%;
  width: 100%;
}

// login style

.hui_login_page {
  .login_outer_page_bg {
    background-image: url("./assets/img/hui_outer_bg.png");
    height: 480px;
    position: relative;
    contain: content;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    .login_inner_page_bg {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: -80px;
      img {
        max-width: 37%;
      }
    }
  }
  .login_outer_page {
    .login_form_container {
      margin: -130px auto;
      width: 570px;
      height: auto;
      box-shadow: 0px 10px 99px rgb(0 0 0 / 16%);
      position: relative;
      z-index: 999;
      background-color: #ffffff;
      border-radius: 24px;
      padding: 40px;
      .login_header_sec {
        h3 {
          padding: 0;
          margin: 0;
          font-size: 28px;
          font-weight: 500;
          color: #434343;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          font-weight: 300;
          padding: 0;
          margin: 0;
          color: #a7a7a7;
          line-height: 30px;
          margin-top: 20px;
        }
      }
      form {
        .signin_fields {
          ion-list {
            .field {
              position: relative;
              ion-item {
                .password_visiable {
                  .password_visiable_icon {
                    position: absolute;
                    top: 45px;
                    right: 20px;
                    z-index: 999;
                    cursor: pointer;
                  }
                }
                ion-input {
                  font-weight: 500;
                }
              }
            }
          }
          .login_submit_sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;
            .forgot_sec {
              a {
                font-size: 18px;
                font-weight: 400;
                cursor: pointer;
              }
            }
            .login_button {
              ion-button {
                width: 220px;
                height: 55px;
                --font-size: 18px !important;
                font-weight: 400;
              }
            }
          }
        }
        .passvaild_message {
          background-color: #eeeeee;
          padding: 15px 30px;
          color: #464646;
          margin-top: 15px;
          p {
            margin: 0;
            padding: 0;
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 10px 0px;
          }
        }
      }
    }
  }
} // login style end

// Header Styles

app-language-selection{

  button{
    width: 50px;
  }

}

.header_container {
  .header_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 40px;
    flex-wrap: wrap;
    .header_sec_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      app-language-selection {
        .language_box {
          display: flex;
          flex-direction: row-reverse;
          border-radius: 4px;
          padding: 0;
          margin: 0;
          height: 35px;
          overflow: hidden;
          .language_container_left {
            border-radius: 0 4px 4px 0;
            border-left: unset !important;
          }
          .language_container_right {
            border-radius: 4px 0 0 4px;
            border-right: unset !important;
          }
          .language_container_left,
          .language_container_right {
            display: flex;
            align-items: center;
            border: 1px solid #a8a8a8;
            background-color: transparent;
            cursor: pointer;
            button {
              background-color: unset;
              color: #000;
              padding: 1px 6px;
            }
            img {
              margin-left: 8px;
            }
          }
          .lang-active {
            background-color: #195a03;
            margin: 0;
            height: 35px;
            border-right: unset;
            // margin-bottom: 0.3px;
            button {
              color: #fff;
            }
          }
        }
      }
    }

    .menu_icon_text {
      display: flex;
      align-items: center;
      .menu_icon {
        cursor: pointer;
        width: 30px;
      }
      .menu_tittle {
        ion-title {
          font-weight: 700;
          font-size: 26px;
          padding: 0 20px;
          position: relative;
        }
      }
    }
    .user_profile {
      ion-buttons {
        background: #f7f7f7;
        margin: 0;
        .top_avatar {
          ion-grid {
            ion-row {
              ion-col {
                .user_name {
                  font-weight: 600;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
  ion-toolbar {
    .header_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      padding: 10px 0;
      .user_profile {
        app-header {
          ion-row {
            flex-direction: row-reverse;
            color: #000000;
          }
        }
        ion-toolbar {
          app-language-selection {
            .language_box {
              display: flex;
              flex-direction: row-reverse;
              border-radius: 4px;
              padding: 0;
              margin: 0;
              height: 35px;
              overflow: hidden;
              .language_container_left {
                border-radius: 0 4px 4px 0px;
                border-left: unset !important;
              }
              .language_container_right {
                border-radius: 4px 0px 0px 4px;
                border-right: unset !important;
              }
              .language_container_left,
              .language_container_right {
                display: flex;
                align-items: center;
                border: 1px solid #a8a8a8;
                background-color: transparent;
                cursor: pointer;

                // border-left: unset;
                button {
                  background-color: unset;
                  color: #131313;
                  font-weight: 500;
                  padding: 1px 6px;
                }
                img {
                  margin-left: 8px;
                }
                &:nth-child(2) {
                  // border-left: unset;
                }
              }
              .lang-active {
                background-color: #195a03;
                margin: 0;
                height: 35px;
                // border-radius: 4px 0px 0px 4px;
                border-right: unset;
                button {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .header_logo {
        img {
          width: 60%;
          cursor: pointer;
        }
      }
    }
    .top_avatar {
      text-align: right;
      margin-right: 0px;
      display: block;
      ion-row {
        flex-direction: row-reverse;
        margin-right: 10px;
        ion-col {
          text-align: right;
          .user_name {
            font-size: 18px;
            font-weight: 600;
            color: #434343;
            text-transform: uppercase;
            cursor: pointer;
            display: block;
          }
          .user_mail {
            font-size: 16px;
            font-weight: 400;
            color: #434343;
            cursor: pointer;
          }
        }
      }
      ion-avatar {
        width: 45px;
        height: 45px;
        background: #195a03;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 30px;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 25px;
      }
    }
  }
  &::after {
    background-image: unset !important;
    border-bottom: 1px solid #dadada;
    left: 0;
    bottom: -5px;
    background-position: left 0 top -2px;
    position: absolute;
    width: 100%;
    height: 5px;
    background-repeat: repeat-x;
    content: "";
  }
}

body {
  &.en {
    .lang-active {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  &.zh {
    .lang-active {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

// Footer

.footer {
  background-color: #333333;
  ion-grid {
    padding: 0;
    margin: 0;
    .top_footer_container {
      padding: 80px 0 80px 0;
      background-color: #333333;
      color: #ffffff;
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      padding-right: 15px;
      padding-left: 15px;

      ion-col {
        h3 {
          margin: 0 0 20px;
          padding: 0 0 20px;
          position: relative;
          font-size: 18px !important;
          font-weight: 700;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0px;
            height: 2px;
            width: 50px;
            background: #eee;
          }
          p {
            &:hover {
              color: unset;
              cursor: unset;
            }
          }
        }
        ion-row {
          p {
            color: #ffffff;
            font-size: 14px !important;
            line-height: 26px;
            padding: 5px 25px 0px 0px;
            font-weight: 400;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
              color: #ffffff !important;
              text-decoration: none !important;
              &:hover {
                color: #77be2d !important;
                cursor: pointer;
              }
            }
            ion-icon {
              color: #77be2d !important;
              margin-right: 5px;
            }
        
          }
        }
        .foo_list{
          ion-row{
            p{
              &:hover {
                color: unset !important;
                cursor: default;
              }
            }
          }
        }
      }
    }
    .tiny_footer {
      background-color: #0e181e;
      padding: 6px 0px;
      color: #ffffff;
      .tiny_container {
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        ion-row {
          ion-col {
            p {
              color: #ffffff;
              font-size: 14px !important;

              a{
                color: #ffffff !important;
                text-decoration: none !important;
                &:hover {
                  color: #1B6004 !important;
                  cursor: pointer;
                }
              }

            }
          }
        }
      }
    }
  }
}

// Success & Failed Modals

ion-modal {
  &::part(content) {
    width: 450px;
  }
  ngx-custom-dialog {
    background: #ffffff;
    .set_alert_popup {
      .header {
        padding: 30px 30px 0px;
        flex-direction: column;
        align-items: center;
        .icon_sec {
          font-size: 100px;
          line-height: 0;

          &.success-img {
            color: #01af18;
          }
          &.failure-img {
            color: #ff0000;
          }
          &.conform-img {
            color: #3100ff;
          }
        }
        .header_text {
          width: 100%;
          text-align: center;
          padding: 20px 5px 0px;
          h2 {
            margin: 0;
            font-size: 30px;
            color: #000;
          }
          p {
            color: #000;
            width: 95%;
            margin: 10px auto 5px;
            line-height: 30px;
            padding: 0;
            a {
              text-decoration: none;
            }
          }
          .error-code {
            color: #bbbbbb;
            margin: 0 30px 20px;
            padding: 0;
            font-weight: 500;
            font-size: 12px;
            text-align: left;
          }
        }
      }

      .description-popup {
        background: #ffffff;
        padding: 20px;
        width: 87%;
        margin: 0 auto 40px auto;
        p {
          line-height: 28px;
        }
        &:empty {
          display: none;
        }
      }
      .modal_footer {
        background: #ffffff;
        width: 100%;
        display: flex;
        padding: 30px;
        align-items: center;
        justify-content: center;

        .transparent_btn {
          height: 60px;
          text-align: center;
          background-color: #195a03;
          color: #fff;
          border-radius: 4px;
          width: 284px;
          &::part(native) {
            padding: 15px 20px;
          }
        }
      }
    }
  }
  &.Success,
  &.Failed,
  &.Conform,
  &.sess-modal,
  &#order-payment-err {
    --height: auto;
    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }
}

// Form Modal (Login, Register, OTP)
.form-modal {
  padding: 50px;
  .title {
    text-align: center;
    margin: 0px 0px 40px 0px;
    h2 {
      font-size: 30px;
      font-weight: 400;
      color: $theme_default_color;
      margin: 0 0 10px 0;
    }
    h6 {
      font-size: 18px;
      color: #a7a7a7;
      font-weight: 300;
    }
  }
  .description {
    ion-label {
      font-size: 18px;
    }
  }

  ion-input {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 12px 0px;
    color: #6d6d6d;
    height: 56px;
    padding-inline-start: 12px !important;
  }
  ion-button {
    &.theme_red_btn {
      width: 284px;
      height: 56px;
      background: $theme_default_color;
      --border-radius: 10px;
      border-radius: 10px;
      margin: 15px auto 0px auto;
      display: block;
    }
  }
  .hide-err {
    color: #fa0000;
    margin-top: -15px;
  }
  .login_text {
    text-align: center;
    margin-top: 30px;
    a {
      cursor: pointer;
    }
  }
  .form-group {
    margin-bottom: 25px;
  }
  .otp_sec {
    text-align: center;
    ion-label {
      line-height: 28px;
    }

    .edit_email {
      display: inline-block;
      background: #fcf4f4;
      padding: 6px;
      border-radius: 5px;
      margin-left: 20px;
      cursor: pointer;
    }
    ion-buttons {
      width: 100%;
      display: block;
      text-align: right;
      padding-right: 43px;
      text-transform: capitalize;
      .counter {
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 54px;
        background: #fcf4f4;
        padding: 6px;
        border-radius: 5px;
      }
    }
    .email {
      text-transform: lowercase;
    }
  }
  .error-msg {
    text-align: center;
    color: #fa0000;
  }
  .resend_success {
    color: #000;
    display: block;
    width: 80%;
    margin: 15px auto 0px auto;
    background: #cdf7d3 !important;
    padding: 5px;
    border: 1px solid #01af18;
    border-radius: 5px;
  }
}

ion-modal {
  &.login_modal {
    --height: auto;
    --min-height: 425px !important;
    --max-height: 100% !important;
    .modal_footer {
      padding-top: 3px;
    }
  }
  &.register_modal {
    --height: auto;
    --min-height: 700px !important;
    --max-height: 100% !important;
    .modal_footer {
      padding-top: 3px;
    }
  }
  &.otp_modal {
    --height: auto;
    --min-height: 480px !important;
    --max-height: 100% !important;
    .form-modal ion-button.theme_red_btn {
      margin: 20px auto 0px auto;
    }
    .modal_footer {
      padding-top: 3px;
    }
  }
}

.hide-err {
  color: #fa0000;
  padding: 0;
  margin: 0;
  padding-top: 8px;
}
ion-popover {
  &::part(content) {
    --offset-x: 0px;
    --offset-y: 00px;
  }
  .popover-viewport {
    ion-list {
      padding: 0;
      .select-interface-option {
        &::part(native) {
          --border-radius: 4px;
        }
      }
      ion-item {
        position: relative;
        color: $theme_default_color;
        padding: 5px 10px;

        &:hover {
          cursor: pointer;
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: unset;
          display: block;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 9;
          margin: 0 auto;
        }

        ion-icon {
          color: $theme_default_color;
          margin-right: 10px;
        }
      }
    }
  }
}

//payment histry page

.payment_page {
  width: 100%;
  background-color: white;
  .payment_history {
    padding: 60px 0 80px 0;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    .head {
      padding-bottom: 60px;
      span {
        font-size: 49px;
        font-weight: bold;
        color: #1c1f3e;
      }
    }
    .price_table {
      ion-row {
        ion-col {
          &:first-child {
            flex: unset;
            width: 60%;
          }
        }
      }
      .payment_desc {
        display: flex;
        flex-direction: column;
        padding-bottom: 55px;
        .head {
          font-size: 24px;
          font-weight: bold;
          color: #000000;
          margin: 0;
          padding-bottom: 5px;
        }
        .sub_text {
          font-size: 20px;
          font-weight: 400;
          color: #a1a4ac;
        }
      }
      .payment_status {
        .status_item_paid {
          font-size: 20px;
          font-weight: 500;
          word-wrap: break-word;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #12880e;
            border-radius: 50%;
            margin-right: 10px;
          }
          &:empty {
            display: none;
          }
        }
        .status_item_pending {
          font-size: 20px;
          font-weight: 500;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #f9893e;
            border-radius: 50%;
            margin-right: 10px;
          }
          &:empty {
            display: none;
          }
        }
        .status_item_fail {
          font-size: 20px;
          font-weight: 500;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #ff3333;
            border-radius: 50%;
            margin-right: 10px;
          }
          &:empty {
            display: none;
          }
        }
      }

      .payment_price {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
//member card

.member_card_box {
  background-image: url(./assets/svg/FlowerWhite.svg);
  background-color: #195a03;
  position: relative;
  padding: 80px 0px 40px;
  display: block;
  .container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    .member_image_card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .front_img,
      .back_img {
        width: 48%;
      }
    }
    .box_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px 50px;
      .text {
        span {
          font-size: 34px;
          color: #fff;
          font-weight: 500;
          position: relative;
          display: block;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -15px;
            height: 3px;
            width: 217px;
            background: rgb(255, 255, 255);
          }
        }
      }
      .btn {
        app-button {
          ion-button {
            background-color: #ffffff;
            border-radius: 5px;
            &::part(native) {
              background-color: #ffffff;
              color: #195a03;
              font-size: 14px;
              font-weight: 500;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.show_error_code {
  // width: max-content;
  span {
    color: #c5c5c5 !important;
    font-size: 12px;
    &:last-child {
      font-weight: 500 !important;
      font-size: 12px;
    }
  }
}

.error_val {
  color: #ff0000 !important;
  border-radius: 5px;
  font-size: 14px !important;
}
//filp card
.app-membership-cards {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 70px 0px;
  overflow: scroll;
  .flip-container {
    width: 31.33%;
    height: 228px;
    --ion-card-background: transparent;
    box-shadow: unset;
    border-radius: 15px;
    margin: 0 !important;

    .flipper {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transform-style: preserve-3d;
      transition: all 0.8s ease;
      .front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background-color: transparent;
        color: #000;
        .change {
          float: right;
          margin: 15px;
          ion-button {
            &::part(native) {
              border: 1px solid #258e26;
              color: #195a03;
              background-color: transparent;
              box-shadow: none;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
        .selected {
          float: right;
          margin: 15px;
          ion-button {
            &::part(native) {
              color: #ffffff;
              box-shadow: none;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }

        .life {
          background-image: url(./assets/svg/life-membership.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #ffb600;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
        .life-zh {
          background-image: url(./assets/svg/life-membership-zh.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #ffb600;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
        .associate {
          background-image: url(./assets/svg/associate-membership.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #00d7d7;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
        .associate-zh {
          background-image: url(./assets/svg/associate-membership-zh.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #00d7d7;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
        .ordinary {
          background-image: url(./assets/svg/ordinary-membership.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #3eb31c;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
        .ordinary-zh {
          background-image: url(./assets/svg/ordinary-membership-zh.svg);
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-position: center center;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 2px solid #3eb31c;
          background-size: cover;
          cursor: pointer;
          border-radius: 15px;
          padding: 15px;
        }
      }
      .back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        backface-visibility: hidden;
        overflow: hidden;
        background: #fafafa;
        color: #333;
        text-align: center;
        transform: rotateY(180deg);
        background-image: url(./assets/svg/backflip.svg);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: rotateY(180deg);
      }
      .flip-card-back {
        padding: 0px 20px;
        width: 100%;
        line-height: 25px;
        text-align: center;
        .m-title {
          font-weight: 600;
        }
        .m-desc {
          span {
            font-size: 14px;
            font-weight: 400;
            color: #474747;
          }
        }
        .m-price {
          span {
            font-size: 14px;
            font-weight: 400;
            color: #474747;
          }
        }
        .register_btn {
          width: 212px;
          height: 52px;
          background: #195a03;
          text-decoration: none;
          color: #fff;
          margin: 0 auto;
          text-align: center;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.8px;
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}

//single page
app-membership-cards {
  .app-membership-cards {
    &.single_page {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 100%;
      max-width: 1180px;
      margin: 0 auto;
      padding: 70px 0px;
      .flip-container {
        height: 245px !important;
        .flipper {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          transform-style: preserve-3d;
          transition: all 0.8s ease;
        }
      }
    }
  }
}

/* Footer Card */
.chechout_card_box_design {
  .app-membership-cards {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 70px 0px;
    .flip-container {
      width: 31.33%;
      height: 245px;
      --ion-card-background: transparent;
      box-shadow: unset;
      border-radius: 15px;
      margin: 0 !important;

      .flipper {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transform-style: preserve-3d;
        transition: all 0.8s ease;
      }
    }
  }
}

//register_form
.page_content {
  width: 100%;
  background-color: #ffffff;
  .form_page_container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    .page_container {
      .form_container {
        margin-bottom: 100px;
        &:last-child {
          margin-bottom: 0;
        }
        .filed_container {
          display: flex;
          width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          .title {
            width: 32%;
            .sub_text {
              font-size: 24px;
              font-weight: bold;
              color: #a8a8a8;
              padding: 0;
              margin: 0;
            }
            .inner_title {
              font-size: 26px;
              font-weight: bold;
              text-transform: uppercase;
              color: #195a03;
              .inner_sub {
                font-size: 18px;
              }
            }
          }
          .input_container {
            width: 66%;
            ion-item {
              .content_text {
                .text {
                  app-check-box-component {
                    ion-list {
                      ion-item {
                        app-label {
                          ion-label {
                            padding: 0;
                            margin: 0;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 30px;
                            color: #1d1d1d;
                            text-overflow: unset;
                            overflow: visible;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .card_input_tittle {
              padding: 30px 0px 0px;
              span {
                font-size: 24px;
                font-weight: bold;
                color: #5a5a5a;
              }
            }
            ion-card {
              box-shadow: unset;
              padding: 0;
              margin: 0;
              ion-card-content {
                padding: 0;
                margin: 0;
                .tooltip_input {
                  position: relative;
                  .tooltip_text {
                    position: absolute;
                    top: 50px;
                    right: 30px;
                  }
                }
                .datapick_box {
                  width: 100%;
                  display: flex;
                  height: 64px;
                  border: 1px solid var(--ion-border-color);
                  border-radius: var(--border-radius-xs);
                  align-items: center;
                  margin-top: 30px;

                  app-label {
                    position: absolute;
                    ion-label {
                      transform: translateY(-150%) !important;
                      background: var(--ion-white-color);
                      z-index: 99;
                      position: relative;
                      padding: 0px 10px;
                      left: 10px;
                      display: table;
                    }
                  }
                  .dob,
                  .month,
                  .date {
                    width: 100%;
                    margin-left: 5px;
                    ion-col {
                      padding-bottom: unset !important;
                      padding-top: unset;
                      padding-inline-start: unset;
                      padding-inline-end: unset;
                      ion-item {
                        app-select-component {
                          width: 100%;
                          .form-group {
                            ion-select {
                              max-width: 94%;
                              --padding-top: 10px;
                              --padding-end: 0;
                              --padding-bottom: 5px;
                              --padding-start: 16px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ion-item {
                  &.item-interactive-disabled {
                    ion-label {
                      opacity: 0.5;
                      position: relative;
                      z-index: 99;
                    }
                    ion-input {
                      background: #f7f7f7;
                    }
                  }
                  app-label {
                    width: 100%;
                  }
                  padding: 0;
                  margin: 5px 0px;
                  .two_side_btn {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    .left {
                      width: 28%;
                    }
                    .right {
                      width: 71%;
                      position: absolute;
                      right: 0;
                    }
                  }
                  &.file_uploaded {
                    overflow: inherit;
                    app-label {
                      transform: translateY(-120%) !important;
                      padding-left: 0;

                      ion-label {
                        background-color: #fff;
                        flex: unset !important;
                        width: unset !important;
                        min-width: unset !important;
                        max-width: unset !important;
                        padding: 0px 10px;
                      }
                    }
                    .image_update_box {
                      .upload_img {
                        height: 62px;
                        width: auto;
                      }
                      app-file-upload {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f7fdf4;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                      }
                    }
                  }
                }
              }
            }
            .checkbox_styles {
              .content_text {
                .text {
                  padding-bottom: 20px;
                  p {
                    padding: 0;
                    margin: 0;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                  }
                  app-check-box-component {
                    ion-list {
                      ion-item {
                        ion-checkbox {
                          margin-right: 15px;
                          &::part(container) {
                            border-radius: 4px;
                            border: 2px solid #195a03;
                          }
                        }
                        app-label {
                          ion-label {
                            text-transform: capitalize;
                            span {
                              // color: #01af18;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.card_footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  .card_design {
    position: relative;
    background-color: #ffffff;
    z-index: 99;
    .app-membership-cards {
      .flip-container {
        .flipper {
          &:hover {
            transform: unset;
          }
        }
      }
    }
  }
  .form_footer {
    background-color: #195a03;
    color: #ffffff;
    .footer_container {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      padding: 20px 0px;
      ion-footer {
        &::before {
          display: none;
        }
        ion-row {
          ion-col {
            padding: 0;
            margin: 0;
            .amout_box {
              display: flex;
              flex-direction: column;
              cursor: pointer;
              .amout_text_value {
                display: flex;
                align-items: flex-end;
                .amount_value {
                  font-size: 40px;
                  font-weight: 500;
                }
                .amout_icon {
                  ion-icon {
                    font-size: 18px;
                    margin-left: 10px;
                  }
                }
              }
              .amount_text {
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
          .order_btn {
            text-align: end;
            app-button {
              ion-button {
                &::part(native) {
                  width: 280px;
                  height: 60px;
                  font-size: 20px;
                  font-weight: 500;
                  border-radius: 6px;
                  border: 1px solid #ffffff;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.membership_form_header {
  background-color: #f7fdf4;
  .checkout_container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    .ckeckout_row {
      display: flex;
      align-items: center;
      padding: 40px 0px;
      justify-content: space-between;
      .checkout_card {
        width: 32%;
        .app-membership-cards {
          width: 100%;
          padding: 0px;
          margin: 0;
          .flip-container {
            margin: 0;
            padding: 0;
            .flipper {
              &:hover {
                transform: unset;
              }
            }
          }
        }
      }
    }

    .checkout_content {
      width: 67%;
      padding: 0px 0px 0px 80px;
      .checkout_txt_head {
        padding-bottom: 30px;
        span {
          font-size: 40px;
          font-weight: bold;
          color: #434343;
          text-transform: uppercase;
        }
      }
      .checkout_txt_sub {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 18px;
          font-weight: 400;
          width: 60%;
          line-height: 30px;
        }
        app-button {
          ion-button {
            &::part(native) {
              border: 1px solid #195a03;
              color: #195a03;
              background-color: #f7fdf4;
              box-shadow: none;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

app-checkout {
  .page_content {
    padding-bottom: 120px;
    padding-top: 70px;
  }
}

app-user-membership-det {
  ion-content {
    app-header {
      .header_container {
        &::after {
          border-bottom: unset;
        }
      }
    }
  }
}

// admin side menu
.menu-side-start {
  // width: 300px;
  --background: #ffffff;
  border: unset !important;
  .side_menu_logout {
    position: absolute;
    bottom: 30px;
    width: 100%;
    cursor: pointer;
    ion-list {
      ion-item {
        ion-label {
          font-size: 16px !important;
          font-weight: 500;
          color: #195a03;
        }
        ion-icon {
          margin-inline-end: 32px;
          color: #195a03;
          transform: rotate(180deg);
        }
      }
    }
  }
  .sidemenu_content {
    --background: #ffffff !important;
    .header_part {
      padding: 25px 0px;
      .side_bar_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 135px;
        }
      }
    }
    ion-list {
      ion-item {
        padding: 5px 25px;
        cursor: pointer;
        &.inactive {
          color: red;
          ion-label {
            font-size: 16px !important;
            font-weight: 500;
            color: #a8a8a8;
          }
          ion-icon {
            color: #a8a8a8;
          }
        }
        &.active {
          color: #195a03;
          background-color: #efefef;
          border-bottom: 2px solid #195a03;
          ion-icon {
            color: #195a03;
          }
          ion-label {
            font-size: 16px !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}

// admin content page

app-admin {
  ion-content {
    background-color: #f5f5f5;

    .site_header {
      height: 90px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      padding: 0 40px;
      &::after {
        background-image: unset;
        border-bottom: 1px solid #dadada;
      }
    }
    .admin_page_sec {
      z-index: 9;
      .filter_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 40px;

        .table_filter {
          width: 270px;
        }
        .table_actions {
          display: flex;
          align-items: center;
          position: relative;
          .filter_btn_new {
            margin-right: 15px;
          }
          .filter_applied {
            margin-right: 15px;
          }
          .action_btn {
            text-align: end;
            position: relative;
            ion-button {
              --background: #ecfce4;
              height: 44px;
              --border-radius: 5px;
              --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);
              ion-icon {
                color: #000;
                font-size: 18px;
              }
            }
          }
          .filter_btn {
            position: relative;
          }
          .setting_btn {
            ion-button {
              margin-left: 16px;
            }
          }
          .filter_content {
            top: 75%;
            background: #fff;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            box-shadow: 0 0 5px 2px #00000024;
            z-index: 99999;
            width: 200px;
            margin-top: 20px;
            &.show_setting_sec {
              position: absolute;
              right: 0;
            }
            &::before {
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              transform: rotate(180deg);
              right: 20px;
              top: -12px;
              border-top: 10px solid #f1f2f6;
            }
            &::after {
              top: -10px;
              border-top: 10px solid #f1f2f6;
            }
            .list_heading {
              background: #f1f2f6;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              h3 {
                font-size: 14px;
                padding: 16px;
                margin: 0;
                text-align: left;
              }
            }
            .show_data_list {
              padding: 16px;
              position: relative;
              .show_data_item {
                display: flex;
                align-items: center;
                padding-bottom: 16px;
                ion-checkbox {
                  margin: 0 10px 0 0;
                  width: 14px;
                  height: 14px;
                  visibility: visible !important;
                  -webkit-touch-callout: none !important;
                  -webkit-tap-highlight-color: unset !important;
                  &::part(container) {
                    border-color: #195a03;
                  }
                  // &.checkbox-checked {
                  //   background-color: transparent;
                  // }
                }
                ion-label {
                  padding: 0;
                  font-size: 14px !important;
                  letter-spacing: unset !important;
                  margin: 0;
                  line-height: 18px;
                  white-space: nowrap;
                  width: 140px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                }
              }
            }
            &.show_filter_sec {
              position: absolute;
              right: 0;
            }
          }
        }
      }
      .view_detailTable {
        padding: 0 40px;
        margin-bottom: 50px;
        .no_data_found {
          .table-responsive {
            table {
              tbody {
                tr {
                  td {
                    text-align: center;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .dataTable {
          .custom-smart-table {
            padding: 30px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #ebebeb;
            .pagination-top {
              display: none;
            }
          }
          .table-responsive {
            table {
              thead {
                background-color: #f5f5f5;
                tr {
                  th {
                    ng2-smart-table-title {
                      max-width: 100%;
                      height: 56px;
                      display: block;
                      a {
                        color: #434343;
                        font-size: 15px;
                        font-weight: 600;
                        max-width: 100%;
                        height: 56px;
                        display: block;
                        line-height: 60px;
                        text-decoration: none !important;
                        text-align: left;
                        &::after {
                          border-bottom-color: #707070 !important;
                          border: 5px solid transparent;
                        }
                      }
                      span {
                        color: #434343;
                        font-size: 15px;
                        font-weight: 600;
                        max-width: 100%;
                        height: 56px;
                        display: block;
                        line-height: 60px;
                        text-decoration: none !important;
                        text-align: left;
                        margin-left: 10px;
                      }
                    }
                    &:last-child {
                      border-top-right-radius: 10px;
                    }
                    &:first-child {
                      border-top-left-radius: 10px;
                    }
                    &.amount {
                      text-align: end;
                      a {
                        text-align: end;
                      }
                    }
                  }
                }
              }
              tbody {
                tr {
                  border-bottom: 1px solid #e6e6e6;
                  background-color: #ffffff;
                  &:last-child {
                    border-bottom: unset;
                  }

                  td {
                    padding: 10px 15px;
                    cursor: pointer;
                    ngx-action-popover {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .table_view {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: white;
                        line-height: 33px;
                        text-align: center;
                        border: 1px solid #195a03;
                        color: #195a03;
                        margin-left: 10px;
                        cursor: pointer;

                        &.disabled{
                          border: 1px solid #999999;
                          color: #999999;
                          cursor: default !important;
                          pointer-events: unset !important;
                          .action-icon{
                            .c-send{
                              visibility: hidden;
                            }
                          }
                        }

                        ion-icon {
                          pointer-events: none;
                        }

                        &:hover {
                          .action-icon {
                            position: relative;
                            .action-tooltip {
                              background-color: #195a03;
                              display: flex;
                              width: 200px;
                              height: 30px;
                              position: absolute;
                              left: -85px;
                              border-radius: 4px;
                              color: #fff;
                              align-items: center;
                              text-align: center;
                              justify-content: center;

                              &.c-edit {
                                left: -10px;
                                width: 50px;
                              }
                              &.c-resend {
                                left: -157px;
                                width: 237px;
                              }
                              &.c-send {
                                left: -185px;
                                width: 220px;
                              }
                            }
                          }
                        }
                      }
                    }
                    ng2-smart-table-cell {
                      table-cell-view-mode {
                        div {
                          font-size: 15px !important;
                          font-weight: 400;
                          &.label-text {
                            display: none;
                          }
                        }
                        .status_item_paid {
                          font-weight: 500;
                          border-radius: 5px;
                          font-size: 14px;
                          width: 125px;
                          word-wrap: break-word;
                          display: flex;
                          align-items: center;
                          color: #5eb60c;

                          &::before {
                            content: "";
                            background: #5eb60c;
                            width: 9px;
                            height: 9px;
                            display: block;
                            border-radius: 50%;
                            margin-right: 10px;
                          }
                        }
                        .status_item_pending {
                          color: #fc811e;
                          font-weight: 500;
                          border-radius: 5px;
                          font-size: 14px;
                          width: 125px;
                          word-wrap: break-word;
                          display: flex;
                          align-items: center;
                          &::before {
                            content: "";
                            background: #fc811e;
                            width: 9px;
                            height: 9px;
                            display: block;
                            border-radius: 50%;
                            margin-right: 10px;
                          }
                        }
                        .status_item_fail {
                          color: #ff0000;
                          font-weight: 500;
                          border-radius: 5px;
                          font-size: 14px;
                          width: 125px;
                          word-wrap: break-word;
                          display: flex;
                          align-items: center;
                          &::before {
                            content: "";
                            background: #ff0000;
                            width: 9px;
                            height: 9px;
                            display: block;
                            border-radius: 50%;
                            margin-right: 10px;
                          }
                        }
                      }
                    }
                    .Ordinary {
                      color: #055f32;
                    }
                    .Lifetime {
                      color: #daa520;
                    }
                    .Associate {
                      color: #009999;
                    }

                    &:last-child {
                      border-bottom: unset;
                    }
                    .admin_amount {
                      text-align: end;
                      padding-right: 0px;
                    }
                    p {
                      color: #1d1d1d;
                      font-size: 16px !important;
                      font-weight: 400;
                      margin: 0;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#huihui-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .pagenation_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    #huihui-footer-pag-length {
      margin-right: 20px;
    }
  }

  #huihui-footer-row-option {
    .huihui-row-option {
      .filter-selection {
        display: flex;
        align-items: center;
        color: #11141a;
        font-size: 14px;
        font-weight: 400;
        .custom_select {
          width: 100%;
          height: auto;
          background: #fff;
          padding: 0;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
          color: #828282;
          display: flex;
          align-items: center;
          position: relative;
          ion-select {
            padding: 0px;
            width: 60px;
            height: 44px;
            text-align: center;
            font-size: 16px;
          }
        }
        .filter-selection_name {
          margin-left: 20px;
        }
      }
    }
  }

  .pagination-bottom {
    box-shadow: 0px 4px 13px rgb(0 0 0 / 7%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #ffffff;
    ul.pagination {
      margin: 0;
      li {
        text-align: center;
        margin: 0px 5px;
        position: relative;
        a {
          text-decoration: none;
          border: none !important;
          color: $theme_default_color;
          padding: 0 !important;
          width: 28px;
          height: 28px;
          display: inline-block;
          border-radius: 50%;
          cursor: pointer;
          outline: 0;
          --link-text-focus-color: transparent;
          --link-text-hover-color: transparent;
          span {
            display: block !important;
            font-size: 28px;
            line-height: 25px;
            cursor: pointer;
            outline: 0;
          }
          &:focus {
            outline: 0 !important;
            box-shadow: unset !important;
          }
          &.page-link-next {
            span {
              visibility: hidden;
            }
            &:after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              background: transparent;
              border-top: 1px solid $theme_default_color;
              border-left: 1px solid $theme_default_color;
              transition: all 0.25s ease-in-out;
              transform: rotate(138deg);
              right: 0px;
              left: 10px;
              display: block;
              top: 11px;
              visibility: visible;
            }
          }

          &.page-link-prev {
            span {
              visibility: hidden;
            }
            &:after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              background: transparent;
              border-top: 1px solid $theme_default_color;
              border-left: 1px solid $theme_default_color;
              transition: all 0.25s ease-in-out;
              transform: rotate(316deg);
              right: 0px;
              left: 10px;
              display: block;
              top: 11px;
              visibility: visible;
            }
          }
        }
        &:last-child {
          padding: 0;
          margin: 0px 5px;
        }
      }
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: 0;
        li {
          &.active {
            span {
              background: $theme_default_color;
              color: $text_white_color;
              border-radius: 50%;
              width: 28px;
              height: 28px;
              line-height: 25px;
              text-align: center;
              border: none;
              padding: 0;
              display: block;
            }
          }
          a {
            width: 28px;
            height: 28px;
            line-height: 30px;
            text-align: center;
            color: $theme_default_color;
            border-radius: 50%;
            display: block;
            padding: 0 !important;
            margin: 0 !important;
            cursor: pointer;
            outline: 0;
          }
        }
      }
    }
  }
}

app-payment-reports {
  ion-content {
    .admin_page_sec {
      .view_detailTable {
        .custom-smart-table {
          .table-responsive {
            table {
              tbody {
                tr {
                  td {
                    cursor: unset;

                    .stripe-id {
                      cursor: pointer !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// update admin page

.admin_update_page {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 130px;
  .edit_header {
    padding: 25px 50px;
    background-color: #195a03;
    color: #ffffff;
    text-align: start;
    margin-top: 25px;
    ion-title {
      font-size: 24px;
      font-weight: 500;
      padding: 5px 0px;
      position: unset;
      text-align: left;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .update_input {
    padding-top: 50px;
    background-color: #ffffff;
    padding-bottom: 50px;
    app-member-form {
      .member_form_component {
        .card_footer {
          .form_footer {
            background-color: #ffffff;
            color: #ffffff;
            box-shadow: 10px 0px 12px rgb(0 0 0 / 20%);
            .footer_container {
              height: 100px;
              ion-footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                -webkit-margin-end: 20px;
                .cancel_btn {
                  ion-button {
                    &::part(native) {
                      background-color: #ffffff;
                      color: #195a03;
                      font-size: 18px;
                      box-shadow: unset;
                      padding: 25px 30px;
                    }
                  }
                }
                .submit_btn {
                  ion-button {
                    &::part(native) {
                      font-size: 18px;
                      font-weight: 400;
                      padding: 25px 30px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dataTable {
  table {
    th,
    td {
      &:last-child {
        width: 136px !important;
      }

      &.email_1 {
        width: 100% !important;
      }

      &.no,
      &.sNo {
        width: 78px !important;
      }
    }
  }

  th {
    &.no,
    &.sNo {
      min-width: 78px !important;
    }

    &.name {
      min-width: 150px !important;
      width: 20% !important;
    }
    &.address {
      min-width: 160px !important;
      width: 20% !important;
    }
    &.zip_code {
      min-width: 122px;
      width: 20%;
    }
    &.member_id {
      min-width: 150px !important;
      width: 14% !important;
    }
    &.membership_type {
      min-width: 150px !important;
      width: 13% !important;
    }
    &.email {
      min-width: 150px !important;
      width: 19% !important;
    }
    &.mobile_no {
      min-width: 150px !important;
      width: 14% !important;
    }
    &.muslim_name{
      min-width: 150px !important;
      width: 20% !important;
    }
    &.expiry_date {
      min-width: 150px !important;
      width: 14% !important;
    }
    &.action {
      min-width: 150px !important;
    }

    &.date {
      min-width: 150px !important;
      width: 18% !important;
    }
    &.order_id {
      min-width: 150px !important;
      width: 10% !important;
    }
    &.transaction_status {
      min-width: 190px !important;
      width: 18% !important;
    }
    &.status {
      min-width: 150px !important;
      width: 10% !important;
    }
    &.amount {
      min-width: 150px !important;
    }
  }
}

.admin_page_sec {
  &.applied-manage-column {
    .dataTable {
      table {
        th {
          width: auto !important;
          &.name {
            min-width: 250px !important;
          }
          &.member_id {
            min-width: 200px !important;
          }
          &.email {
            min-width: 250px !important;
          }
        }
      }
    }
  }
}

.no_manage_columns {
  width: 48%;
  margin: 40px auto 0px;

  ion-title {
    padding: 0 !important;
    margin-bottom: 15px;
  }

  .dataTable {
    table {
      width: 100%;

      th {
        width: 33.333% !important;
      }

      td {
        padding: 15px;
      }
    }
  }
}

app-member-form {
  ion-item.item-label-floating.item-has-value {
    ion-label {
      font-size: 15px !important;
    }
  }
}

ionic-selectable-modal {
  ion-header {
    ion-toolbar {
      border-bottom: 1px solid #dadada;

      ion-buttons {
        ion-button {
          .popup_cancel_btn {
            font-size: 25px;
            color: #000000;
          }
        }
      }
      ion-title {
        span {
          color: #195a03;
        }
      }
    }
  }
}

.ion-color-danger {
  --ion-color-base: #195a03 !important;
}

//Responsive css

@media only screen and (max-width: 1600px) {
  .admin_update_page {
    .update_input {
      padding: 50px 50px;
    }
  }

  app-login-page ion-item ion-input::after {
    height: 43px;
  }

  .split-pane-visible {
    .admin_page_sec {
      .view_detailTable {
        .dataTable {
          .table-responsive {
            table {
              display: block;
              max-width: -moz-fit-content;
              max-width: fit-content;
              margin: 0 auto;
              overflow-x: auto;
              white-space: break-spaces;
            }
          }
        }
      }
    }
  }

  .admin_page_sec {
    .view_detailTable {
      .dataTable {
        .table-responsive {
          table {
            display: block;
            max-width: -moz-fit-content;
            max-width: unset;
            margin: unset;
            overflow-x: auto;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .hui_login_page {
    .login_outer_page_bg {
      height: 410px;
      .login_inner_page_bg {
        margin-top: -60px;
        img {
          width: 50%;
        }
      }
    }
    .login_outer_page {
      .login_form_container {
        width: 30%;
        padding: 30px;
        border-radius: 20px;
        .login_header_sec {
          h3 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
            margin-top: 0;
          }
        }
        form {
          .signin_fields {
            ion-list {
              .field {
                ion-item {
                  ion-input {
                    height: 45px;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .form_item {
                    app-label {
                      ion-label {
                        font-size: 12px !important;
                      }
                    }
                  }
                  .password_visiable {
                    .password_visiable_icon {
                      top: 30px;
                    }
                  }
                }
              }
            }
            .login_submit_sec {
              margin-top: 8px;
              .forgot_sec {
                a {
                  font-size: 16px;
                }
              }
              .login_button {
                ion-button {
                  width: 170px;
                  height: 46px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  .member_card_box {
    padding: 60px 20px 30px;
    .container {
      .box_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 50px;
        .text {
          span {
            font-size: 34px;
            color: #fff;
            font-weight: 500;
            position: relative;
            display: block;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -15px;
              height: 3px;
              width: 217px;
              background: rgb(255, 255, 255);
            }
          }
        }
        .btn {
          app-button {
            ion-button {
              background-color: #ffffff;
              border-radius: 5px;
              &::part(native) {
                background-color: #ffffff;
                color: #195a03;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
  .payment_page {
    .payment_history {
      .price_table {
        ion-row {
          ion-col {
            &:first-child {
              flex: unset;
              width: 60%;
            }
          }
        }
      }
    }
  }
} /* media - 1279 tap hr  */

/* media - 1440 mac */
@media only screen and (max-width: 1440px) {
  .showCaptcha_sec {
    .agent-captcha {
      width: 65%;
      .d-flex {
        width: 90%;
        svg {
          width: 125px;
        }
        span {
          ion-button {
            font-size: 13px;
          }
        }
      }
    }

    a24-input {
      width: 40%;
      ion-input {
        height: 45px;
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }


  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          margin-bottom: 0px;
          .filed_container {
            flex-direction: column;
            padding: 40px 40px 40px;
            .title {
              width: 100%;
            }
            .input_container {
              width: 100%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .card_footer {
    .form_footer {
      .footer_container {
        padding: 20px 20px;
      }
    }
  }

  app-admin {
    ion-content {
      .admin_page_sec {
        .view_detailTable {
          .dataTable {
            .table-responsive {
              table {
                display: block;
                max-width: -moz-fit-content;
                max-width: fit-content;
                margin: 0 auto;
                overflow-x: auto;
                white-space: break-spaces;
                th {
                  a {
                    font-size: 15px !important;
                    font-weight: 600;
                  }
                  &.name {
                    min-width: 200px !important;
                  }
                  &.address {
                    min-width: 240px !important;
                  }
                  &.zip_code {
                    min-width: 150px !important;
                  }
                  &.membership_type {
                    min-width: 170px !important;
                  }
                }
                tbody {
                  tr {
                    td {
                      ng2-smart-table-cell {
                        table-cell-view-mode {
                          div {
                            font-size: 15px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ion-button {
    font-size: 16px;
  }
  .hui_login_page {
    .login_outer_page_bg {
      height: 400px;
      overflow: hidden;
      .login_inner_page_bg {
        margin-top: -60px;
        img {
          width: 100%;
          max-width: 46%;
        }
      }
    }
    .login_outer_page {
      .login_form_container {
        margin: -60px auto !important;
        width: 470px;
        padding: 25px;
        border-radius: 20px;
        .login_header_sec {
          h3 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
            margin-top: 10px;
            line-height: 18px;
          }
        }
        form {
          .signin_fields {
            ion-list {
              .field {
                ion-item {
                  ion-input {
                    height: 45px;
                    font-size: 14px;
                    font-weight: 500;
                    &:after {
                      height: 43px !important;
                    }
                  }
                  .form_item {
                    app-label {
                      ion-label {
                        font-size: 12px !important;
                      }
                    }
                  }
                  .password_visiable {
                    .password_visiable_icon {
                      top: 30px;
                    }
                  }
                }
              }
            }
            .login_submit_sec {
              margin-top: 8px;
              .forgot_sec {
                a {
                  font-size: 14px;
                }
              }
              .login_button {
                ion-button {
                  width: 150px;
                  height: 36px;
                  font-size: 14px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .member_card_box {
    padding: 60px 20px 70px;
    .container {
      .box_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 60px;
        .text {
          span {
            font-size: 34px;
            color: #fff;
            font-weight: 500;
            position: relative;
            display: block;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -15px;
              height: 3px;
              width: 217px;
              background: rgb(255, 255, 255);
            }
          }
        }
        .btn {
          app-button {
            ion-button {
              background-color: #ffffff;
              border-radius: 5px;
              &::part(native) {
                background-color: #ffffff;
                color: #195a03;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
  .payment_page {
    .payment_history {
      .price_table {
        ion-row {
          ion-col {
            &:first-child {
              flex: unset;
              width: 60%;
            }
          }
        }
      }
    }
  }
  .admin_update_page {
    .update_input {
      padding: 0 !important;
    }
  }
} /* media - 1440 mac */

@media only screen and (max-width: 1280px) {
  .hui_login_page {
    .login_outer_page_bg {
      .login_inner_page_bg {
        img {
          width: 100%;
          max-width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1236px) {
  .hui_login_page {
    .login_outer_page {
      .login_form_container {
        margin: -50px auto !important;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .hui_login_page {
    .login_outer_page {
      .login_form_container {
        margin: -50px auto !important;
      }
    }
  }

  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            flex-direction: column;
            padding: 0px 30px;
            .title {
              width: 100%;
            }
            .input_container {
              width: 100%;
            }
          }
        }
      }
    }
  }

  app-admin {
    ion-content {
      .admin_page_sec {
        .view_detailTable {
          .dataTable {
            .table-responsive {
              table {
                display: block;
                max-width: -moz-fit-content;
                max-width: fit-content;
                margin: 0 auto;
                overflow-x: auto;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            flex-direction: column;
            padding: 0px 30px;
            .title {
              width: 100%;
            }
            .input_container {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 0;
      .app-membership-cards {
        padding: 0;
        width: 100%;
        margin: auto;
        height: auto !important;
        .flip-container {
          margin: 10px auto !important;
          padding: 0px !important;
          height: 245px;
          width: 31.333%;
          background-color: transparent;
          .flipper {
            width: 100%;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 100%;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 20px;
        .flip-container {
          height: 245px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  .admin_update_page {
    .update_input {
      padding: 0 !important;
    }
  }
  // app-checkout {
  //   app-membership-cards {
  //     .app-membership-cards {
  //       .flip-container {
  //         .flipper {
  //           width: 340px;
  //           margin: 0 auto;
  //         }
  //       }
  //     }
  //   }
  // }
} /* media - 1279 tap hr  */

@media only screen and (max-width: 1180px) {
  .membership_form_header {
    .checkout_container {
      .ckeckout_row {
        display: flex;
        align-items: center;
        padding: 40px 20px;
        justify-content: space-between;
      }
    }
  }

  .hui_login_page {
    .login_outer_page_bg {
      height: 310px;
    }
    .login_outer_page {
      .login_form_container {
        margin: -150px auto;
      }
    }
  }

  .showCaptcha_sec {
    .agent-captcha {
      width: 65%;
      .d-flex {
        width: 90%;
        svg {
          width: 125px;
        }
        span {
          ion-button {
            font-size: 13px;
          }
        }
      }
    }

    a24-input {
      width: 40%;
      ion-input {
        height: 45px;
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }

  .hui_login_page {
    .login_outer_page_bg {
      .login_inner_page_bg {
        img {
          width: 100%;
          max-width: 44%;
        }
      }
    }
  }

  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 8px;
      .app-membership-cards {
        padding: 0;
        width: 100%;
        margin: auto;
        height: auto !important;
        .flip-container {
          margin: 10px auto !important;
          padding: 0px !important;
          height: 245px !important;
          width: 31.333%;
          background-color: transparent;
          .flipper {
            width: 100% !important;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 100%;
                background-size: cover;
              }
              ion-button {
                --padding-start: 10px;
                --padding-end: 10px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          height: 230px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  // app-membership-cards .app-membership-cards .flip-container .flipper {
  //   width: 336px;
  //   margin: 0px auto;
  // }
}
@media only screen and (max-width: 1024px) {
  .hui_login_page {
    .login_outer_page_bg {
      .login_inner_page_bg {
        img {
          width: 100%;
          max-width: 50%;
        }
      }
    }
  }
}
@media only screen and (max-width: 946px) {
  .chechout_card_box_design {
    .membership_form_header {
      .checkout_container {
        .ckeckout_row {
          flex-direction: column;
          .checkout_card,
          .checkout_content {
            width: 100%;
            padding: 30px;
          }
        }
      }
    }
  }

  .chechout_card_box_design {
    #sel-membership-desc {
      .checkout_container {
        .ckeckout_row {
          .checkout_card {
            app-membership-cards {
              .app-membership-cards {
                .flip-container {
                  .flipper {
                    .front {
                      .ordinary,
                      .associate,
                      .life {
                        width: 100%;
                        height: 220px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 8px;
      .app-membership-cards {
        padding: 0;
        width: 100%;
        margin: auto;
        height: auto !important;
        .flip-container {
          margin: 10px auto !important;
          padding: 0px !important;
          height: 200px !important;
          width: 31.333%;
          background-color: transparent;
          .flipper {
            width: 100% !important;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 100%;
                background-size: cover;
              }
              ion-button {
                --padding-start: 10px;
                --padding-end: 10px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          height: 200px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            flex-direction: column;
            padding: 0px 30px;
            .title {
              width: 100%;
            }
            .input_container {
              width: 100%;
            }
          }
        }
      }
    }
  }

  app-admin {
    ion-content {
      .admin_page_sec {
        .view_detailTable {
          .dataTable {
            .table-responsive {
              table {
                display: block;
                max-width: -moz-fit-content;
                max-width: fit-content;
                margin: 0 auto;
                overflow-x: auto;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  .hui_login_page {
    .login_outer_page_bg {
      height: 450px;
      .login_inner_page_bg {
        margin-top: -50px;
        img {
          width: 100%;
          max-width: 700px;
        }
      }
    }
    .login_outer_page {
      .login_form_container {
        margin: -75px auto;
        width: 65%;
        form {
          .signin_fields {
            .login_submit_sec {
              .forgot_sec {
                a {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .login_button {
                ion-button {
                  width: 160px;
                  height: 42px;
                }
              }
            }
          }
        }
      }
    }
  }
  .member_card_box {
    padding: 60px 20px 70px;
    .container {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      .box_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        .text {
          span {
            font-size: 34px;
            color: #fff;
            font-weight: 500;
            position: relative;
            display: block;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -15px;
              height: 3px;
              width: 217px;
              background: rgb(255, 255, 255);
            }
          }
        }
        .btn {
          app-button {
            ion-button {
              background-color: #ffffff;
              border-radius: 5px;
              &::part(native) {
                background-color: #ffffff;
                color: #195a03;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    background-color: #333333;
    ion-grid {
      padding: 0;
      margin: 0;
      .top_footer_container {
        padding: 40px 15px;

        ion-col {
          &:nth-child(2) {
            margin-left: 20px;
          }
          &:nth-child(3) {
            margin-left: 20px !important;
          }
          ion-row {
            p {
              color: #ffffff;
              font-size: 14px !important;
              padding: 0px 25px 0px 0px;
            }
          }
        }
      }
      .tiny_footer {
        .tiny_container {
          padding: 15px 20px;
          ion-row {
            ion-col {
              p {
                color: #ffffff;
                font-size: 14px !important;
                padding: 0px;
                margin: 0;
                ion-icon {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .payment_page {
    .payment_history {
      .price_table {
        ion-row {
          ion-col {
            &:first-child {
              flex: unset;
              width: 60%;
            }
          }
        }
      }
    }
  }

  .header_container {
    ion-toolbar {
      .header_container {
        padding: 15px 20px;
        .user_profile {
          app-header {
            ion-row {
              flex-direction: row-reverse;
              color: #000000;
            }
          }
        }
        .header_logo {
          img {
            width: 80%;
            cursor: pointer;
          }
        }
      }
      .top_avatar {
        text-align: right;
        margin-right: 0px;
        display: block;
        ion-row {
          flex-direction: row-reverse;
          ion-col {
            text-align: right;
            .user_name {
              font-size: 18px;
              font-weight: 600;
              color: #434343;
              text-transform: uppercase;
              cursor: pointer;
              display: block;
            }
            .user_mail {
              font-size: 16px;
              font-weight: 400;
              color: #434343;
              cursor: pointer;
            }
          }
        }
        ion-avatar {
          width: 45px;
          height: 45px;
          background: #195a03;
          color: #ffffff;
          text-align: center;
          line-height: 45px;
          font-size: 30px;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
      toolbar-container {
        background-color: #f7f7f7;
      }
    }
  }

  app-login-page {
    ion-item {
      ion-input {
        &::after {
          height: 43px;
        }
      }
    }
  }

  app-checkout {
    .page_content {
      padding: 50px 35px 120px;
    }
  }

  .card_footer {
    .form_footer {
      .footer_container {
        padding: 20px 25px;
      }
    }
  }

  .membership_form_header {
    .checkout_container {
      .ckeckout_row {
        flex-direction: column;
        .checkout_content {
          width: 100%;
          padding: 30px 20px;
        }
        .checkout_card {
          width: 100%;
        }
      }
    }
  }

  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 8px;
      .app-membership-cards {
        padding: 0;
        width: 100%;
        margin: auto;
        height: auto !important;
        .flip-container {
          margin: 10px auto !important;
          padding: 0px !important;
          height: 172px !important;
          width: 31.333%;
          background-color: transparent;
          .flipper {
            width: 100% !important;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 100%;
                background-size: cover;
              }
              ion-button {
                --padding-start: 10px;
                --padding-end: 10px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          height: 165px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  .header_container {
    .header_sec {
      .menu_icon_text {
        .menu_tittle {
          ion-title {
            font-weight: 700;
            font-size: 26px;
            position: unset;
            padding: 0 20px;
          }
        }
      }
    }
  }

  .showCaptcha_sec {
    .agent-captcha {
      width: 45%;
      .d-flex {
        width: 100%;
        svg {
        }
        span {
          ion-button {
            font-size: 14px;
          }
        }
      }
    }

    a24-input {
      width: 40%;
      ion-input {
        height: 45px;
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }
  .admin_update_page {
    .update_input {
      padding: 0 !important;
    }
  }
} /* media 820 tap vr */

@media only screen and (max-width: 767px) {
  app-checkout {
    app-membership-cards {
      .app-membership-cards {
        .flip-container {
          .flipper {
            .front {
              .ordinary,
              .associate,
              .life {
                width: 90% !important;
                height: 245px !important;
              }
            }
          }
        }
      }
    }
  }

  .header_container {
    ion-toolbar {
      .top_avatar {
        width: 52px;
        margin-left: 0px;
      }
    } 
    .header_sec .header_sec_left app-language-selection .language_box {
      margin-right: 20px;
    }
  }


  .chechout_card_box_design {
    #sel-membership-desc {
      .checkout_container {
        .ckeckout_row {
          .checkout_card {
            app-membership-cards {
              .app-membership-cards {
                .flip-container {
                  .flipper {
                    .front {
                      .ordinary,
                      .associate,
                      .life {
                        width: 90% !important;
                        height: 191px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          width: 90%;
          height: 245px !important;
          margin: 10px 0px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            .input_container {
              ion-card {
                ion-card-content {
                  ion-item {
                    app-label {
                      width: 80%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // .header_container {
  //   ion-toolbar {
  //     .top_avatar {
  //       ion-row {
  //         flex-direction: unset;
  //       }
  //     }
  //   }
  // }

  app-member-form ion-item {
    --padding-start: 10px !important;
    --padding-end: 10px !important;
  }

  .upload_files_v2 {
    padding: 0 10px !important;
    padding: 0 10px !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --min-height: 60px !important;
    app-label {
      width: 100% !important;
      ion-label {
        width: 100% !important;
      }
    }
    &.item-has-value, &.file_uploaded {
      app-label {
        width: 50% !important;
      }
    }
  }

  .tooltip_input {
    .tooltip_small_text {
      // top: 80px;
      // right: 30px;
      .tooltip-bottom {
        ion-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  app-member-form {
    ion-item.item-label-floating.item-has-value {
      ion-label {
        font-size: 12px !important;
        white-space: pre-wrap;
        width: auto;
      }
    }
  }

  .admin_update_page {
    .edit_header {
      padding: 25px 20px;
    }
  }

  .upload_files_v2 {
    app-label {
      ion-label {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
      }
    }
  }

  // Success & Failed Modals

  ion-modal {
    &::part(content) {
      background-color: #ffffff;
      width: 90%;
      border-radius: 4px;
    }

    ngx-custom-dialog {
      background: #ffffff;
      .set_alert_popup {
        .header {
          padding: 15px 15px 0px;
          .icon_sec {
            font-size: 50px;

            &.success-img {
              color: #01af18;
            }
            &.failure-img {
              color: #ff0000;
            }
            &.conform-img {
              color: #3100ff;
            }
          }
          .header_text {
            width: 100%;
            text-align: center;
            padding: 20px 5px 0px;
            h2 {
              margin: 0;
              font-size: 20px;
            }
            p {
              color: #000;
              width: 90%;
              margin: 15px auto 15px;
              line-height: 20px;
              padding: 0;
              font-size: 12px;
            }
          }
        }

        .description-popup {
          background: #ffffff;
          padding: 20px;
          width: 87%;
          margin: 0 auto 40px auto;
          p {
            line-height: 28px;
          }
          &:empty {
            display: none;
          }
        }
        .modal_footer {
          padding: 10px 30px;
          .transparent_btn {
            width: 160px;
            height: 50px;
          }
        }
      }
    }
    &.Success {
      --height: auto;
      --min-height: 285px !important;
      --max-height: 100% !important;
    }
    &.Failed {
      --height: auto;
      --min-height: 285px !important;
      --max-height: 100% !important;
    }
    &.Conform {
      --height: auto;
      --min-height: 285px !important;
      --max-height: 100% !important;
    }
    &.sess-modal {
      --height: auto;
      --min-height: 285px !important;
      --max-height: 100% !important;
    }
  }

  // table
  app-admin {
    ion-content {
      .admin_page_sec {
        .view_detailTable {
          .dataTable {
            .table-responsive {
              table {
                display: block;
                max-width: -moz-fit-content;
                max-width: fit-content;
                margin: 0 auto;
                overflow-x: hidden;
                white-space: nowrap;
                thead {
                  th {
                    display: none;
                  }
                }
                tbody {
                  tr {
                    display: flex;
                    flex-wrap: wrap;
                    td {
                      display: block;
                      width: 50% !important;
                      .label-text {
                        display: block !important;
                        font-weight: 500;
                        color: #195a03;
                        margin-bottom: 6px;
                      }
                      ngx-action-popover {
                        justify-content: flex-start;
                        .table_view {
                          &:first-child {
                            margin-left: 0;
                          }
                        }
                      }
                      ng2-smart-table-cell {
                        table-cell-view-mode {
                          .cell-root-sec {
                            display: inline-block;
                            text-overflow: ellipsis;
                            width: calc(100%);
                            div {
                              &.table_text {
                                display: block;
                                text-overflow: unset;
                                width: 100%;
                                white-space: break-spaces;
                              }
                            }
                          }
                        }
                      }
                      &.status {
                        div {
                          &.table_text {
                            white-space: unset !important;
                          }
                        }
                      }
                      .admin_amount {
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .hui_login_page {
    .login_outer_page_bg {
      height: 230px;
      .login_inner_page_bg {
        margin-top: -30px;
        img {
          max-width: 330px;
          width: 100%;
        }
      }
    }
    .login_outer_page {
      .login_form_container {
        margin: -65px auto;
        width: 90%;
        padding: 20px;

        .login_header_sec {
          h3 {
            font-size: 24px;
          }
          p {
            font-size: 12px;
            margin-top: 10px;
            line-height: 20px;
          }
        }
        form {
          .signin_fields {
            ion-list {
              .field {
                position: relative;
                ion-item {
                  .password_visiable {
                    .password_visiable_icon {
                      top: 29px;
                      right: 12px;
                    }
                  }
                  ion-input {
                    font-weight: 500;
                  }
                }
              }
            }
            .login_submit_sec {
              flex-direction: column-reverse;
              .forgot_sec {
                a {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .login_button {
                ion-button {
                  width: 160px;
                  height: 42px;
                }
              }
            }
          }
        }
      }
    }
  }
  app-login-page {
    ion-item {
      ion-input {
        &::after {
          height: 43px;
        }
      }
    }
  }

  .header-ios ion-toolbar:last-of-type {
    --border-width: 0;
  }

  .header_container {
    ion-toolbar {
      .header_container {
        padding: 15px 5px 15px 15px;
        justify-content: space-between;
        .user_profile {
          app-header {
            ion-row {
              flex-direction: row-reverse;
              color: #000000;
            }
          }
        }
        .header_logo {
          img {
            width: 60%;
            cursor: pointer;
          }
        }
      }
      .top_avatar {
        ion-row {
          ion-col {
            text-align: right;
            margin-right: 20px;
            &.user_detail {
              display: none;
              // .user_name {
              //   font-size: 15px;
              // }
              // .user_mail {
              //   font-size: 13px;
              //   font-weight: 400;
              // }
            }
          }
        }
        ion-avatar {
          width: 37px;
          height: 37px;
          line-height: 37px;
          font-size: 18px;
        }
      }
    }
  }
  .member_card_box {
    padding: 30px 0px 30px;
    .container {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      .box_header {
        justify-content: center;
        flex-direction: column;
        .text {
          margin-bottom: 20px;
          span {
            font-size: 20px;
            color: #fff;
            font-weight: 500;
            position: relative;
            display: block;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  //payment histry page

  .payment_page {
    width: 100%;
    background-color: white;
    .payment_history {
      padding: 20px 15px 40px 15px;
      .head {
        padding-bottom: 25px;
        span {
          font-size: 28px;
          font-weight: bold;
          color: #1c1f3e;
        }
      }
      .price_table {
        ion-row {
          padding-bottom: 30px;
          margin-bottom: 30px;
          border-bottom: 1px solid #f6f6f6;
          .m-desc {
            width: 100% !important;
            flex: unset;
            margin: 0;
            padding: 0;
            .payment_desc {
              padding-bottom: 20px;
              .head {
                font-size: 22px;
              }
              .sub_text {
                font-size: 18px;
              }
            }
          }
          ion-col {
            &:nth-child(2) {
              background: #f5f5f5;
              padding-left: 20px;
            }
            &:nth-child(3) {
              background: #f5f5f5;
              text-align: right;
              padding-right: 20px;
            }
          }
        }

        .payment_status {
          .status_item_paid {
            font-size: 18px;
          }
          .status_item_fail {
            font-size: 18px;
          }
        }

        .payment_price {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
  app-footer {
    .footer {
      background-color: #333333;
      ion-grid {
        padding: 0;
        margin: 0;
        .top_footer_container {
          padding: 40px 15px;
          flex-wrap: wrap;
          flex-direction: column;
          ion-col {
            width: 100% !important;
            max-width: 100% !important ;
            margin-left: 0 !important;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
              margin-left: 0 !important;
            }

            ion-row {
              p {
                color: #ffffff;
                font-size: 14px !important;
                padding: 0px 25px 0px 0px;
              }
            }
          }
        }
        .tiny_footer {
          .tiny_container {
            flex-direction: column;
            ion-row {
              ion-col {
                p {
                  color: #ffffff;
                  font-size: 14px !important;
                  padding: 0px;
                  margin: 0;
                  ion-icon {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 8px;
      .app-membership-cards {
        flex-direction: column;
        padding: 0;
        width: 100%;
        margin: 20px 0px;
        height: auto !important;

        .flip-container {
          margin: 10px 0px;
          padding: 0;
          height: 200px;
          margin: 10px auto !important;
          padding: 0px 0px !important;
          width: 100%;
          .flipper {
            width: 90%;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 50%;
                background-size: cover;
                height: 237px;
              }
            }
          }
        }
      }
    }
  }

  .card_footer {
    .form_footer {
      .footer_container {
        padding: 20px 10px;
        ion-footer {
          &::before {
            display: none;
          }
          ion-row {
            ion-col {
              .amout_box {
                cursor: pointer;
                .amount_text {
                  font-size: 14px !important;
                }
                .amount_value {
                  font-size: 24px !important;
                }
              }
            }
            .order_btn {
              text-align: end;
              app-button {
                ion-button {
                  &::part(native) {
                    width: 150px;
                    height: 40px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .membership_form_header {
    background-color: #f7fdf4;
    .checkout_container {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      .ckeckout_row {
        padding: 20px 0px;
        flex-direction: column;
        .checkout_card {
          width: 100%;
          margin: 0 auto;
          padding: 15px;
          .app-membership-cards {
            width: 100%;
            padding: 0px;
            margin: 0;
            .flip-container {
              width: 100% !important;
              margin: 0 !important;
              padding: 0 !important;
              background-color: transparent;
              height: 258px !important;
              .flipper {
                &:hover {
                  transform: unset;
                }
              }
            }
          }
        }
      }

      .checkout_content {
        width: 100%;
        padding: 20px 15px;
        .checkout_txt_head {
          padding-bottom: 30px;
          span {
            font-size: 30px;
          }
        }
        .checkout_txt_sub {
          flex-wrap: wrap;
          padding-top: 0;
          span {
            font-size: 18px;
            font-weight: 400;
            width: 100%;
            line-height: 30px;
            padding-bottom: 20px;
          }
          app-button {
            ion-button {
              &::part(native) {
                border: 1px solid #195a03;
                color: #195a03;
                background-color: #f7fdf4;
                box-shadow: none;
                font-weight: 600;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  app-checkout {
    .page_content {
      padding: 50px 9px 120px;
    }
  }

  .member_form_component {
    .page_content {
      .form_page_container {
        .page_container {
          .form_container {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .tooltip_input .tooltip_text {
    right: 30px !important;
  }

  .pl-custom {
    text-indent: 10px;
  }

  .page_content {
    width: 100%;
    background-color: #ffffff;
    .form_page_container {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      .page_container {
        .form_container {
          margin-bottom: 100px;
          &:last-child {
            margin-bottom: 0;
          }
          .filed_container {
            flex-direction: column;
            padding: 30px 0px;
            .title {
              width: 100%;
              padding: 0px 15px 20px;
              .sub_text {
                font-size: 20px;
                font-weight: bold;
                color: #a8a8a8;
                padding: 0;
                margin: 0;
              }
              .inner_title {
                font-size: 22px;
                font-weight: bold;
                text-transform: uppercase;
                color: #195a03;
                .inner_sub {
                  font-size: 15px;
                }
              }
            }
            .input_container {
              width: 95%;
              margin: 0 auto;
              .card_input_tittle {
                padding: 30px 0px 20px;
                span {
                  font-size: 22px;
                  font-weight: bold;
                  color: #5a5a5a;
                }
              }
              ion-card {
                box-shadow: unset;
                padding: 0;
                margin: 0;
                ion-card-content {
                  padding: 0;
                  margin: 0;
                  .datapick_box {
                    // flex-direction: column;
                    width: auto;
                    display: flex;
                    height: 64px;
                    border: 1px solid var(--ion-border-color);
                    border-radius: var(--border-radius-xs);
                    align-items: center;
                    margin: 30px 10px 0px;
                    .dob,
                    .month,
                    .date {
                      // height: 92px;
                      width: 100%;
                      margin-left: 0;
                      ion-col {
                        margin: 0;
                        padding: 0;
                        ion-item {
                          app-select-component {
                            .form-group {
                              ion-select {
                                max-width: 95%;
                                --padding-top: 10px;
                                --padding-end: 0;
                                --padding-bottom: 0;
                                --padding-start: 8px;

                                &::part(icon) {
                                  width: 20px;
                                  height: 20px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ion-item {
                    padding: 0;
                    margin: 0px;

                    .two_side_btn {
                      display: flex;
                      width: 100%;
                      flex-wrap: wrap;
                      .left {
                        width: 100%;
                      }
                      .right {
                        margin-top: 10px;
                        width: 100%;
                      }
                    }
                  }
                }
              }
              .checkbox_styles {
                .content_text {
                  .text {
                    padding-bottom: 20px;
                    p {
                      padding: 0;
                      margin: 0;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .chechout_card_box_design {
    app-member-form {
      .member_form_component {
        .card_footer {
          .card_design {
            app-membership-cards {
              .app-membership-cards {
                .flip-container {
                  height: 250px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  // admin page responsive

  #huihui-footer {
    flex-direction: column;
    padding: 0 20px;
    .pagination-bottom {
      padding: 6px 5px;
      border-radius: 8px;
      ul.pagination {
        span {
          li {
            a {
              line-height: 25px;
            }
          }
        }
      }
    }
    #huihui-footer-pag-length {
      padding: 15px 0px;
    }
    .huihui-footer-pag {
      padding: 10px 0px;
    }
    #huihui-footer-row-option {
      .huihui-row-option {
        .filter-selection {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    }
  }

  // admin tabel design

  app-admin {
    ion-content {
      .admin_page_sec {
        .view_detailTable {
          padding: 0 10px;
          .dataTable {
            .custom-smart-table {
              padding: 15px;
              border-radius: 5px;
            }
          }
        }
        .filter_sec {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          flex-direction: column;
          margin-top: 10px;
          .table_actions {
            justify-content: flex-end;
            width: 100%;
            margin-top: 15px;
            padding: 0;
          }
        }
      }
    }
  }

  // admin header page

  .header_container {
    &:after {
      background-image: unset !important;
      border-bottom: 1px solid #dadada;
    }
    .header_sec {
      padding: 8px 10px 8px 5px;
      align-items: center;
      .menu_icon_text {
        .menu_icon {
          width: 35%;
          padding: 0px 8px;
        }
        .menu_tittle {
          width: 75%;
          ion-title {
            padding: 0 0 0 5px;
            position: relative;
            font-size: 16px;
          }
        }
      }
      .user_profile {
        .wrapper {
          ion-toolbar {
            background-color: transparent;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
            width: 50px;
          }
        }
      }
    }
  }

  .header_container {
    .header_sec_left {
      app-language-selection {
        .language_box {
          display: flex;

          .language_container {
            // border-left: 0px;
            cursor: pointer;
            button {
              background-color: unset;
              font-size: 10px;
            }
            img {
              margin-left: 0 !important;
              width: 45%;
            }
          }
        }
      }
    }
  }

  .member_card_box {
    margin-bottom: 30px;
    .container {
      .member_image_card {
        flex-direction: column;
        .front_img,
        .back_img {
          width: 97%;
        }
      }
    }
  }
  .upload_files_v2 {
    .image_update_box {
      .form_image_upload {
        .upload_image_text {
          img {
            height: 60px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .upload_files_v2 {
    .image_update_box {
      margin-top: 0px;
      margin-right: 1px;
      .form_image_upload {
        width: 78%;
        .image_name_text {
          width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
          white-space: pre;
        }
      }
      app-file-upload {
        height: 60px;
        z-index: 99999;
        top: 0px;
        right: 0px;
        .image_upload_cancel_btn {
          position: absolute;
          left: 40%;
          top: 6px;
          ion-icon {
            font-size: 14px;
          }
        }
        ion-input {
          height: 60px;
        }
      }
    }
  }

  .huihui-backdrop {
    .card_footer {
      .card_design {
        height: 100vh;
        overflow-y: scroll;
        padding: 80px 0px 40px;
      }
      .form_footer {
        position: relative;
        z-index: 999;
      }
    }
  }

  .header_container {
    .header_sec {
      .header_sec_left {
        app-language-selection {
          .language_box .lang-active {
            background-color: #195a03;
            background-color: #195a03;

            border: 1px solid #195a03;
            margin-bottom: unset;
          }
        }
      }
    }
  }

  #huihui-footer {
    .pagenation_text {
      flex-direction: column-reverse;
      #huihui-footer-pag-length {
        margin-right: 0;
      }
    }
  }

  app-admin ion-content {
    .admin_page_sec {
      .filter_sec {
        .table_filter {
          width: 100%;
        }
      }
    }
  }

  .search_bar {
    a24-search {
      .search_input {
        ion-searchbar {
          .searchbar-input-container {
            .searchbar-input {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            .input_container {
              .checkbox_styles {
                .content_text {
                  .text {
                    app-check-box-component {
                      ion-list {
                        ion-item {
                          display: flex;
                          flex-wrap: wrap;
                          align-items: center;
                          justify-content: space-between;
                          --padding-start: 0;
                          --min-height: 30px;
                          margin: 0 !important;
                          app-label {
                            ion-label {
                              width: 100%;
                              white-space: pre-wrap;
                              margin-top: 0px;
                            }
                          }
                          ion-checkbox {
                            margin-right: 15px;
                            margin-top: 0;
                            margin-bottom: 0;
                          }
                        }
                      }
                      .hide-err {
                        padding-top: 0;
                        padding-bottom: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tooltip_input {
    .tooltip_text {
      ion-icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .showCaptcha_sec {
    .agent-captcha {
      width: 55%;
      .d-flex {
        width: 100%;
        svg {
          width: 110px;
        }
        span {
          ion-button {
            font-size: 14px;
          }
        }
      }
    }

    a24-input {
      width: 40%;
      ion-input {
        height: 45px;
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }

  app-user-membership-det {
    .header_container {
      ion-toolbar {
        .top_avatar {
          ion-row {
            flex-direction: row-reverse !important;
          }
        }
      }
    }
  }

  .admin_update_page {
    .update_input {
      padding: 0 !important;
    }
  }
} /* media 767 mobile */

@media only screen and (max-width: 411px), screen and (max-height: 840px) {
  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 8px;
      .app-membership-cards {
        flex-direction: column;
        padding: 0;
        width: 100%;
        margin: 20px 0px;
        height: auto !important;

        .flip-container {
          margin: 10px 0px;
          padding: 0;
          height: 200px;
          margin: 10px auto !important;
          padding: 0px 0px !important;
          width: 100%;
          .flipper {
            width: 90%;
            margin: 0 auto;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                width: 50%;
                background-size: cover;
                height: 237px !important;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          width: 88%;
          height: 245px !important;
          margin: 6px 0px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .chechout_card_box_design {
    app-membership-cards {
      overflow: auto !important;
      padding: 5px;
      .app-membership-cards {
        .flip-container {
          height: 200px;
          .flipper {
            width: 100% !important;
            .front {
              .ordinary,
              .associate,
              .life {
                padding: 0;
                // width: 100% !important;
                // height: 172px !important;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }

  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          width: 82%;
          height: 245px !important;
          margin: 6px 0px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  .showCaptcha_sec {
    flex-direction: column;
    .agent-captcha {
      width: 100%;
      .d-flex {
        width: 100%;
        svg {
          width: 110px;
        }
        span {
          ion-button {
            font-size: 14px;
          }
        }
      }
    }

    a24-input {
      width: 100%;
      ion-input {
        height: 45px;
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }

  // admin header page

  .header_container {
    &:after {
      background-image: unset !important;
      border-bottom: 1px solid #dadada;
    }
    .header_sec {
      padding: 8px 5px;
      align-items: center;
      .menu_icon_text {
        width: 50%;
        .menu_icon {
          width: 35%;
          padding: 0px 8px;
        }
        .menu_tittle {
          width: 75%;
          ion-title {
            padding: 0 0 0 5px;
            position: relative;
            font-size: 16px;
          }
        }
      }
      .header_sec_left {
        width: 50%;
      }
      .user_profile {
        .wrapper {
          ion-toolbar {
            background-color: transparent;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
            width: 50px;
          }
        }
      }
    }
  }

  .header_container {
    .header_sec {
      app-language-selection {
        .language_box {
          display: flex;
          border: 1px solid #a8a8a8;
          flex-direction: row-reverse;
          border-radius: 4px;
          .language_container {
            display: flex;
            align-items: center;
            background-color: transparent;
            padding: 5px;
            border-left: 1px solid #a8a8a8;
            cursor: pointer;
            cursor: pointer;
            button {
              background-color: unset;
              font-size: 10px;
            }
            img {
              margin-left: 0;
              width: 35%;
            }
            &:nth-child(2) {
              border-left: unset;
            }
          }
          .lang-active {
            background-color: #195a03;
            background-color: #195a03;

            border: 1px solid #195a03;
            margin-bottom: 0.3px;
            button {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .header_container {
    .user_profile {
      ion-toolbar {
        app-language-selection {
          .language_box {
            display: flex;
            border: 1px solid #a8a8a8;
            flex-direction: row-reverse;
            border-radius: 4px;
            .language_container {
              padding: 5px !important;

              cursor: pointer;
              cursor: pointer;
              button {
                background-color: unset;
                font-size: 10px !important;
              }
              img {
                margin-left: 0 !important;
                width: 35% !important;
              }
            }
          }
        }
      }
    }
  }

  .chechout_card_box_design {
    #sel-membership-desc {
      .checkout_container {
        .ckeckout_row {
          .checkout_card {
            app-membership-cards {
              .app-membership-cards {
                .flip-container {
                  .flipper {
                    .front {
                      .ordinary,
                      .associate,
                      .life {
                        width: 100% !important;
                        height: 191px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-checkout {
    app-membership-cards {
      .app-membership-cards {
        .flip-container {
          .flipper {
            .front {
              .ordinary,
              .associate,
              .life {
                width: 90% !important;

                height: 220px !important;
              }
            }
          }
        }
      }
    }
  }
} /* media 360 mobile */

@media only screen and (max-width: 320px) {
  app-membership-cards {
    .app-membership-cards {
      &.single_page {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        max-width: 1180px;
        margin: 0 auto;
        padding: 70px 10px;
        .flip-container {
          width: 62%;
          height: 245px !important;
          margin: 6px 0px !important;
          .flipper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transform-style: preserve-3d;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }
}

/* Text Version */

.huihui-cards-section {
  &.membership_card {
    .flip-card {
      .inner_txt {
        ion-button {
          position: relative;
          top: unset;
          right: unset;
          text-transform: uppercase !important;
          font-size: 16px;
          width: 210px;
          height: 52px;
        }
      }
    }
  }
}

.chechout_card_box_design {
  .membership_card {
    padding: 40px 0px;
    .flip-card {
      perspective: unset;
      .flip-card-inner {
        transform-style: unset;
        transform: unset;
      }
      &:hover {
        .flip-card-inner {
          transform: unset;
        }
      }
    }
  }
}
app-membership-cards {
  overflow-y: auto !important;
}

.chechout_card_box_design {
  .membership_card {
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.membership_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  margin: 0px auto;

  .flip-card {
    background-color: transparent;
    width: 356px;
    height: 237px;
    perspective: 1000px;
    border-radius: 15px;
    background: url(./assets/svg/backflip.svg);
    &.lifes {
      border: 2px solid #ffb600;
      border-radius: 15px;
      h2 {
        color: #daa520;
      }
      h3 {
        background: rgb(218, 165, 32);
        background: linear-gradient(90deg, rgba(218, 165, 32, 1) 0%, rgba(255, 204, 0, 1) 100%);
      }
    }
    &.ordinarys {
      border: 2px solid #3eb31c;
      border-radius: 15px;
      h2 {
        color: #195a03;
      }
      h3 {
        background: rgb(5, 95, 50);
        background: linear-gradient(90deg, rgba(5, 95, 50, 1) 0%, rgba(122, 208, 58, 1) 100%);
      }
    }
    &.associates {
      border: 2px solid #00d7d7;
      border-radius: 15px;
      h2 {
        color: #009999;
      }
      h3 {
        background: rgb(0, 153, 153);
        background: linear-gradient(90deg, rgba(0, 153, 153, 1) 0%, rgba(4, 226, 226, 1) 100%);
      }
    }
    .inner_txt {
      padding: 10px;
      ion-icon {
        width: 170px;
        height: 150px;
        display: block;
      }
      h2 {
        font-family: "Poppins", sans-serif;
        margin: 0;
        font-size: 70px;
        font-weight: 700;
        letter-spacing: 1px;
        position: absolute;
        display: table;
        padding-left: 40px;
        right: 15px;
        top: 110px;
        span {
          font-size: 54px;
          font-weight: 500;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      h3 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin: 30px 0px 0px;
        text-align: right;
        padding-right: 15px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        letter-spacing: 1px;
      }
      h4 {
        color: #1b1b1b;
        font-weight: 600;
        margin: 5px 0px 0px;
      }
      p {
        line-height: 28px;
      }
      .change {
        ion-button {
          --background: transparent !important;
          border: 1px solid #055f32;
          border-radius: 8px;
          color: #055f32;
          --box-shadow: unset;
          font-weight: 600;
        }
      }
      ion-button {
        position: absolute;
        top: 10px;
        right: 10px;
        --box-shadow: unset;
      }
    }
  }

  .flip-box {
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    margin: 0 auto;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  &.single-card {
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    // width: 100%;
    // height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
  }

  .flip-card-front {
    background: url(./assets/svg/backflip.svg);
    background-size: cover;
    border-radius: 15px;
    z-index: 1;
  }

  .flip-card-back {
    background: url(./assets/svg/backflip.svg);
    background-size: cover;
    color: white;
    transform: rotateY(180deg);
    border-radius: 15px;
    .inner_txt {
      width: 100%;
      height: 226px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
} /* membership card */

@media only screen and (max-width: 1139px) {
  .membership_card {
    width: 100%;
    &.single-card {
      .flip-card {
        width: 356px;
      }
    }
    .flip-card {
      width: 31.333%;
      .inner_txt {
        h2 {
          font-size: 55px;
          top: 130px;
          padding-left: 30px;
          span {
            font-size: 42px;
          }
        }
        h3 {
          font-size: 20px;
          margin-top: 35px;
          padding-right: 10px;
        }
        p {
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .membership_card {
    width: 94%;
    flex-direction: column;
    .flip-card {
      width: 356px;
      margin-bottom: 20px;
      .inner_txt {
        h2 {
          font-size: 55px;
          top: 130px;
          span {
            font-size: 42px;
          }
        }
        h3 {
          font-size: 20px;
          margin-top: 35px;
          padding-right: 10px;
        }
        p {
          line-height: 22px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .membership_card {
    flex-direction: unset;
    justify-content: center;
    &.single-card {
      flex-direction: unset;
    }
  }
  .chechout_card_box_design {
    .membership_card {
      padding: 110px 0 40px;
    }
  }

  #huihui-footer #huihui-footer-row-option .huihui-row-option .filter-selection .custom_select ion-select {
    width: 80px;
    height: 44px;
    text-align: left;
    font-size: 16px;
    padding: 0px 0px 0px 10px;
  }

  app-admin ion-content{
    .admin_page_sec{
      .view_detailTable{
        .dataTable{
          .table-responsive{
            table{
              tbody{
                tr{
                  td{
                    ngx-action-popover{
                      .table_view{
                        &:hover {
                          .action-icon {
                        
                            .action-tooltip {
                              &.c-edit {
                                left: -10px;
                                width: 50px;
                              }
                              &.c-resend {
                                left: -40px;
                                width: 237px;
                              }
                              &.c-send {
                                left: -66px;
                                width: 220px;
                              }
                            }
                          }
                        }
                      }
                    } 
                  } 
                } 
              } 
            } 
          } 
        } 
      } 
    } 
  } 

  .tooltipitem-has-value {
    .tooltip_small_text {
      position: absolute;
      top: 73px;
      right: 30px;
      z-index: 99999;
    }
  }

  app-member-all {
    ion-content {
      app-admin-header {
        .header_sec {
          .header_sec_left {
            .user_profile {
              .page_toolbar {
                .back_arrow {
                  app-c-avatar {
                    ion-row {
                      flex-direction: row;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .page_content {
    .form_page_container {
      .page_container {
        .form_container {
          .filed_container {
            .input_container {
              ion-card {
                ion-card-content {
                  ion-item {
                    app-label {
                      width: 85%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .membership_card {
    width: 94%;
    flex-direction: column;
    a {
      width: 100%;
    }
    .flip-card {
      width: 100%;
      margin: 0 auto 20px auto;
      .inner_txt {
        h2 {
          font-size: 55px;
          top: 130px;
          span {
            font-size: 42px;
          }
        }
        h3 {
          font-size: 15px;
          margin-top: 35px;
          padding-right: 10px;
        }
        p {
          line-height: 22px;
        }
        ion-icon {
          width: 50%;
        }
      }
    }
  }
}
